<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__block header__block_logo">
        <div class="header__menu">
          <div
            class="burger"
            @click="toggleNavigation"
            v-bind:class="{ 'hide': $store.state.app.navigation }"
          >
            <svg class="icon icon-burger">
              <use xlink:href="/img/svg/sprite.svg#burger"></use>
            </svg>
          </div>
        </div>
        <div class="header__logo" @click="scrollToTop()">
          <img src="/img/logo_mobile.svg"/>
        </div>
      </div>
      <div class="header__block">
        <span
          class="trid-owner"
          v-bind:class="{ 'has-trid': TRIDOwn() }"
        >
          Owner: {{ TRIDOwn() > 0 ? TRIDOwn().toFixed(6) : TRIDOwn() }}%
        </span>
        <div class="header__refresh refresh">
          <a class="refresh__link clickable" @click="$emit('getProfile')">
            <svg class="icon icon-refresh">
              <use xlink:href="/img/svg/sprite.svg#refresh"></use></svg
          ></a>
        </div>
        <div
          class="header__notification header-notification notification popup-trigger"
          data-popup=".popup-notifications"
        >
          <a class="notification__link" @click="notificationsShow()"
            >
            <span
              class="notification-counter notification__counter header-notification__counter"
              v-if="$store.state.app.notifications.length"
              >{{ $store.state.app.notifications.length }}</span
            >
            <svg class="icon icon-notifications">
              <use xlink:href="/img/svg/sprite.svg#notifications"></use></svg
          ></a>
        </div>
        <div class="header__profile">
          <div class="profile">
            <div class="profile__wrapper trigger">
              <a class="profile__link trigger-link" href="#">{{ $store.state.auth.email }}</a>
              <div class="profile__img">
                <div class="profile__img-item">
                  <img :src="$store.state.profile.icon" />
                </div>
                <div class="profile__img-verify">
                  <img src="/img/verification.svg" />
                </div>
              </div>
              <div class="trigger-block profile__list">
                <ul class="profile-list">
                  <li class="profile-list__item">
                    <a
                      class="profile-list__link clickable trigger-link"
                      @click="routerPush('/settings/profile')"
                    >
                      {{ $t('components.header.profile') }}
                    </a>
                  </li>
                  <li class="profile-list__item">
                    <a class="profile-list__link clickable" @click="logout(false)">{{ $t('components.header.exit') }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="header__languages">
          <div class="language-select language-header-select">
            <select v-model="$i18n.locale" @change="changeLang">
              <option v-for="(dict, lang) in $i18n.messages" :key="lang" :value="lang">{{ lang.toUpperCase() }}</option>
            </select>
          </div>
        </div> -->
      </div>
    </div>
  </header>
</template>

<script>
import customSelect from 'custom-select';

export default {
  name: 'Header',
  data() {
    return {
    };
  },
  methods: {
    toggleNavigation() {
      this.$store.dispatch('app/toggleNavigation');
    },
    changeLang(e) {
      this.$i18n.locale = e.target.value;

      const data = {
        language: e.target.value.toUpperCase(),
      };

      this.$http.put(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile`, data)
        .then(() => {
          this.$root.snackbar.info(this.$i18n.t('response-messages.success.language-change'));
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        });
    },
    notificationsShow() {
      this.$store.dispatch('app/toggleNotifications');
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  mounted() {
    const triggerBlockLinks = document.querySelectorAll('.trigger-link');

    triggerBlockLinks.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        el.closest('.trigger').classList.toggle('active');
      });
    });

    customSelect('.language-select.language-header-select select');
  },
};
</script>

<style scoped>
.trid-owner {
  margin: 5px 15px 0 0;
  color: rgb(209, 209, 209);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.has-trid {
  color: rgb(255, 255, 96);
}

@media screen and (max-width: 991px){
  .trid-owner {
      display: none;
  }
}
</style>
