<template>
  <div
    class="page-content"
    v-bind:class="{ 'full': !$store.state.app.navigation, 'not-full': $store.state.app.navigation }"
  >
    <div class="page-content__wrapper">
      <div class="page-block">
        <div class="page-block__header nav_header">
          <div class="nav_header__wrapper">
          <p class="page-block__title">
            <router-link to="/">
              <a class="back-arrow">
                <img src="/img/blue-arrow.svg" width="24" height="42"/>
              </a>
              {{ $t("views.home.title") }}
            </router-link>
          </p>
          <div class="wallet-block wallet-block__right">
            <div class="wallet-block__header">
              <div class="wallet-block__title">
                <span>{{ $t(`views.home.wallet-${wallet.walletType}`) }}</span>
                <div class="help-block" @click="toggleHelp()">
                <div class="help-block__wrapper">
                  <div class="help-block__icon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 15.3H8.1V13.5H9.9V15.3ZM11.763 8.325L10.953 9.153C10.305 9.81 9.9 10.35 9.9 11.7H8.1V11.25C8.1 10.26 8.505 9.36 9.153 8.703L10.269 7.569C10.602 7.245 10.8 6.795 10.8 6.3C10.8 5.31 9.99 4.5 9 4.5C8.01 4.5 7.2 5.31 7.2 6.3H5.4C5.4 4.311 7.011 2.7 9 2.7C10.989 2.7 12.6 4.311 12.6 6.3C12.6 7.092 12.276 7.812 11.763 8.325Z"
                        fill="#297BC7"
                      />
                    </svg>
                  </div>
                </div>
                <div class="help-block__content" v-show="help_show">
                  <p
                    class="help-block__text"
                    v-html="$t(`views.home.help-text-${wallet.walletType}`)"
                  ></p>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="page-block__content">
          <div class="wallet-block__content">
            <div
              class="wallet-grid"
              v-if="!$store.state.app.loading && Object.keys(wallet).length"
            >
              <WalletCard
                :wallet="wallet"
                :full="true"
              />
            </div>
            <div class="wallet-grid" v-if="$store.state.app.loading && !Object.keys(wallet).length">
              <LoadingSpinner />
            </div>
          </div>
          <div class="trade-content__item" v-show="!loading">
            <div class="table table-offer">
                <div
                  v-for="row in history"
                  :key="row.date"
                  class="history_rows"
                >
                  <div class="table-row table-row_header table-offer__row table__wrapper table-row__history table-row__history_first">
                    <div class="table-offer__column">
                      <p class="table__title">{{ toLocalTime(row.date).format('MMM D YYYY H:mm') }}</p>
                    </div>
                    <div class="table-offer__column status__column">
                      <p class="table__title">
                        <span
                          class="table__text-label"
                          v-bind:style="{ color: row.status.toUpperCase() === 'COMPLETE' ? '#66FF66' : '#FF3333' }"
                        >{{ row.status.toLowerCase().capitalize() }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="table-row table-offer__row table-row__history table-row__history_second">
                    <div class="table-offer__column">
                      <div class="table-row table-offer__row table-row__history__subrow">
                        <div class="table-offer__column">
                          <p class="table__text">
                        <span class="table__icon" v-if="row.type.toUpperCase() === 'IN'">
                        <svg class="icon icon-arrow-down">
                          <use xlink:href="/img/svg/sprite.svg#arrow-down"></use>
                        </svg>
                        </span>
                        <span class="table__icon" v-if="row.type.toUpperCase() === 'OUT'">
                          <svg class="icon icon-arrow-sent">
                            <use xlink:href="/img/svg/sprite.svg#arrow-sent"></use>
                          </svg>
                        </span>
                        <span
                          class="table__text-label"
                          v-bind:style="{
                            color: ['IN', 'TRANSFER', 'EXCHANGE'].includes(row.type.toUpperCase()) && row.value > 0 ? '#66FF66' : '#FF3333'
                          }"
                        >
                          {{ row.type.toUpperCase() }}
                        </span>
                          </p>
                        </div>
                        <div class="table-offer__column">
                        <p class="table__text"><span class="table__text-label">{{ toFixed(row.value) }}&nbsp;{{ row.currency }}</span></p>
                        </div>
                      </div>
                    </div>
                    <div class="table-offer__column">
                      <div class="small_table_text" v-if="row.information && row.value < 0">
                        <span v-if="row.information.hasOwnProperty('fees')">
                          Fees:&nbsp;{{ toFixed(row.information.fees) }}&nbsp;;&nbsp;
                        </span>
                        <span v-if="row.information.hasOwnProperty('total_commission')">
                            Total commission:&nbsp;{{ toFixed(row.information.total_commission) }}&nbsp;;&nbsp;
                        </span>
                      </div>
                      <div class="small_table_text" v-if="row.type.toUpperCase() != 'EXCHANGE' && row.commission != 0">
                        Commission:&nbsp;{{ toFixed(row.commission) }}&nbsp;;&nbsp;
                      </div>
                    </div>
                  </div>
                  <div class="table-row table-offer__row table-row__history table-row__history_second">
                    <div class="table-offer__column">
                      <div class="small_table_text" v-if="row.information">
                        <p v-if="row.type != 'exchange' && row.information.input_address && row.value > 0">
                          <nobr>From:&nbsp;{{ row.information.input_address }}</nobr>
                        </p>
                        <p v-if="row.type != 'exchange' && row.information.output_address && row.value < 0">
                          <nobr>To:&nbsp;{{ row.information.output_address }}</nobr>
                        </p>
                      </div>
                    </div>
                    <div class="table-offer__column">
                        <span v-if="row.information && row.information.hasOwnProperty('hash')" class="hash_link">
                          <a
                            v-if="
                              getBlockchainInfo(row.currency).basicCurrency == 'ETH'
                              || getBlockchainInfo(row.currency).typeCryptoCurrency == 'TOKEN'
                              || row.currency == 'ETH'
                            "
                            :href="'https://etherscan.io/tx/' + row.information.hash"
                            style="text-decoration: underline;"
                            target="_blank"
                          >
                            Hash:&nbsp;{{ row.information.hash }}
                          </a>
                          <a
                            v-else-if="
                              getBlockchainInfo(row.currency).basicCurrency == 'LTC'
                              || row.currency == 'LTC'
                            "
                            :href="'https://ltc.bitaps.com/' + row.information.hash"
                            style="text-decoration: underline;"
                            target="_blank"
                          >
                            Hash:&nbsp;{{ row.information.hash }}
                          </a>
                          <a
                            v-else-if="
                              getBlockchainInfo(row.currency).basicCurrency == 'BTC'
                              || row.currency == 'BTC'
                            "
                            :href="'https://btc.bitaps.com/' + row.information.hash"
                            style="text-decoration: underline;"
                            target="_blank"
                          >
                            Hash:&nbsp;{{ row.information.hash }}
                          </a>
                          <a
                            v-else-if="
                              getBlockchainInfo(row.currency).basicCurrency == 'BCY'
                              || row.currency == 'BCY'
                            "
                            :href="'https://api.blockcypher.com/v1/bcy/test/txs/' + row.information.hash"
                            style="text-decoration: underline;"
                            target="_blank"
                          >
                            Hash:&nbsp;{{ row.information.hash }}
                          </a>
                          <a
                            v-else-if="
                              getBlockchainInfo(row.currency).basicCurrency == 'BETH'
                              || row.currency == 'BETH'
                            "
                            :href="'https://api.blockcypher.com/v1/beth/test/txs/' + row.information.hash"
                            style="text-decoration: underline;"
                            target="_blank"
                          >
                            Hash:&nbsp;{{ row.information.hash }}
                          </a>
                          <a
                            v-else-if="
                              getBlockchainInfo(row.currency).basicCurrency == 'DOGE'
                              || row.currency == 'DOGE'
                            "
                            :href="'https://blockchair.com/dogecoin/transaction/' + row.information.hash"
                            style="text-decoration: underline;"
                            target="_blank"
                          >
                            Hash:&nbsp;{{ row.information.hash }}
                          </a>
                          <a
                            v-else-if="
                              getBlockchainInfo(row.currency).basicCurrency == 'DASH'
                              || row.currency == 'DASH'
                            "
                            :href="'https://blockchair.com/dash/transaction/' + row.information.hash"
                            style="text-decoration: underline;"
                            target="_blank"
                          >
                            Hash:&nbsp;{{ row.information.hash }}
                          </a>
                          <span v-else>
                            Hash:&nbsp;{{ row.information.hash }}
                          </span>
                        </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="trade-content__item" v-if="loading">
            <LoadingSpinner/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
// import Swiper from 'swiper';
import Wallet from '@/models/Wallet';
import moment from 'moment';
import WalletCard from '@/components/WalletCard.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import store from '@/plugins/store';

export default {
  name: 'WalletHistory',
  data() {
    return {
      wallet: this.$route.params.walletType === 'trade'
        ? new Wallet(store.state.profile.wallets.filter((walletCurr) => this.$route.params.currency === walletCurr.currency)[0])
        : new Wallet(store.state.profile.mainWallets.filter((walletCurr) => this.$route.params.currency === walletCurr.currency)[0]),
      history: [],
      help_show: false,
      loading: false,
      moment,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   let wallet = [];
  //   if (to.params.walletType == 'trade') {
  //     wallet = store.state.profile.wallets.filter((walletCurr) => to.params.currency === walletCurr.currency);

  //     if (!wallet.length) {
  //       next({ name: 'Home' });
  //       return true;
  //     }
  //     next((vm) => {
  //       vm.$set(vm, 'wallet', wallet[0]);
  //       vm.$nextTick(() => {
  //         vm.getData();
  //       });
  //     });
  //     return true;
  //   }

  //   if (to.params.walletType == 'main') {
  //     wallet = store.state.profile.mainWallets.filter((walletCurr) => to.params.currency === walletCurr.currency);
  //     if (!wallet.length) {
  //       next({ name: 'Home' });
  //       return true;
  //     }
  //     next((vm) => {
  //       vm.$set(vm, 'wallet', wallet[0]);
  //       vm.$nextTick(() => {
  //         vm.getData();
  //       });
  //     });
  //     return true;
  //   }

  //   next({ name: 'Home' });
  //   return true;
  // },
  components: {
    WalletCard,
    LoadingSpinner,
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${this.wallet.walletType}/${this.wallet.currency}`)
        .then((response) => {
          this.wallet = new Wallet(response.data.wallet);
          this.history = response.data.operations;

          const tableSlider = new window.Swiper('.table', {
            direction: 'horizontal',
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 0,
            mousewheel: true,
          });
          tableSlider.update();
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        }).then(() => {
          this.loading = false;
        });
    },
    toggleHelp() {
      this.help_show = !this.help_show;
    },
    isEmpty: _.isEmpty,
  },
  watch: {
    '$store.state.app.loading': function () {
      console.log('YEP');
    },
  },
  mounted() {
    // console.log(this.wallet);
    this.getData();
  },
};
</script>

<style>
.trade-content__item {
  margin-top: 50px;
}
.table__text-label span {
  font-weight: 100 !important;
}
/* .page-block {
  max-width: 1650px;
} */
</style>
