<template>
    <div class="popup popup-notifications" style="z-index: 9999; opacity: 1; overflow: auto;">
      <div class="popup__wrapper popup-notifications__wrapper">
        <div class="popup__header">
          <p class="popup__title">{{ $t('components.notifications.title') }}</p>
          <div class="popup__close popup-close" @click="closeNotifications()">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.93468 4.93468 0 11 0C17.0653 0 22 4.93468 22 11C22 17.0653 17.0653 22 11 22C4.93468 22 0 17.0653 0 11ZM13.4955 15.3104L15.3104 13.4955L12.8149 11L15.3104 8.50452L13.4955 6.68962L11 9.18511L8.5045 6.68962L6.6896 8.50452L9.18509 11L6.6896 13.4955L8.5045 15.3104L11 12.8149L13.4955 15.3104Z" fill="#297BC7"/>
            </svg>
          </div>
        </div>
        <div class="popup__content popup-notification__content">
          <div class="popup-notifications__header">
            <div class="tabs notification-tabs">
              <div class="tabs-header">
                <div class="tabs-nav">
                  <div class="tabs-nav__item">
                    <a class="tabs-nav__link" href="#">{{ $t('components.notifications.system') }}</a>
                  </div>
                  <div class="tabs-nav__item">
                    <a class="tabs-nav__link" href="#">{{ $t('components.notifications.admins') }}</a>
                  </div>
                </div>
                <div class="tabs-header__content">
                  <div class="sort">
                    <div class="sort-item clickable" @click="setAllAsRead">
                      <div class="sort-item__icon">
                        <svg class="icon icon-readed ">
                          <use xlink:href="/img/svg/sprite.svg#readed"></use>
                        </svg>
                      </div><span class="sort-item__label">{{ $t('components.notifications.set-all-read') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="popup-notifications__content">
                <div class="tabs-content notification-tabs__content">
                  <div class="tabs-content__item">
                    <div class="notification-block">
                      <div
                        class="notification-block__item"
                        v-for="(notif, key) in $store.state.app.notifications" :key="notif.date"
                      >
                        <div
                          class="notification-item notification-item_chat clickable"
                          v-if="notif.type == 'WALLET'"
                          @click="toggleNotification(notif, key)"
                        >
                          <div class="notification-item__wrapper">
                            <div class="notification-item__date">
                              {{ toNormalDate(notif.date) }}
                              <p class="notification-item__title">{{ $t('components.notifications.new-deposit-title') }}</p>
                              <p class="notification-item__text">
                                {{ $t('components.notifications.new-deposit-text', [notif.infoSmall.currency]) }}.
                                (<b>{{ notif.infoSmall.value }} {{ notif.infoSmall.currency }}</b>)
                              </p>
                              <p class="notification-item__text" v-if="notif.active">
                                <span v-for="(value, key) in notif.infoSmall" :key="key">
                                  {{ key.toUpperCase() }}:
                                  <a
                                    v-if="
                                      key === 'hash'
                                      && (getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'ETH'
                                        || getBlockchainInfo(notif.infoSmall.currency).typeCryptoCurrency == 'TOKEN'
                                        || notif.infoSmall.currency == 'ETH')
                                    "
                                    :href="'https://etherscan.io/tx/0x' + notif.infoSmall.hash"
                                    style="text-decoration: underline;"
                                    target="_blank"
                                  >
                                    {{ value }}
                                  </a>
                                  <a
                                    v-else-if="key === 'hash' && (
                                      getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BTC'
                                        || notif.infoSmall.currency == 'BTC'
                                    )"
                                    :href="'https://btc.bitaps.com/' + notif.infoSmall.hash"
                                    style="text-decoration: underline;"
                                    target="_blank"
                                  >
                                    {{ value }}
                                  </a>
                                  <a
                                    v-else-if="key === 'hash' && (
                                      getBlockchainInfo(notif.infoSmall.currency) == 'LTC' || notif.infoSmall.currency == 'LTC'
                                    )"
                                    :href="'https://ltc.bitaps.com/' + notif.infoSmall.hash"
                                    style="text-decoration: underline;"
                                    target="_blank"
                                  >
                                    {{ value }}
                                  </a>
                                  <a
                                    v-else-if="
                                      key === 'hash' && (
                                      getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BCY'
                                      || notif.infoSmall.currency == 'BCY')
                                    "
                                    :href="'https://api.blockcypher.com/v1/bcy/test/txs/' + notif.infoSmall.hash"
                                    style="text-decoration: underline;"
                                    target="_blank"
                                  >
                                    {{ value }}
                                  </a>
                                  <a
                                    v-else-if="
                                      key === 'hash' && (
                                      getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BETH'
                                      || notif.infoSmall.currency == 'BETH')
                                    "
                                    :href="'https://api.blockcypher.com/v1/beth/test/txs/' + notif.infoSmall.hash"
                                    style="text-decoration: underline;"
                                    target="_blank"
                                  >
                                    {{ value }}
                                  </a>
                                  <a
                                    v-else-if="
                                      getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'DOGE'
                                      || notif.infoSmall.currency == 'DOGE'
                                    "
                                    :href="'https://blockchair.com/dogecoin/transaction/' + notif.infoSmall.hash"
                                    style="text-decoration: underline;"
                                    target="_blank"
                                  >
                                    {{ value }}
                                  </a>
                                  <a
                                    v-else-if="
                                      getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'DASH'
                                      || notif.infoSmall.currency == 'DASH'
                                    "
                                    :href="'https://blockchair.com/dash/transaction/' + notif.infoSmall.hash"
                                    style="text-decoration: underline;"
                                    target="_blank"
                                  >
                                    {{ value }}
                                  </a>
                                  <a
                                    v-else
                                  >
                                    {{ value }}
                                  </a>
                                  <br/>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Notifications',
  data() {
    return {
    };
  },
  methods: {
    closeNotifications() {
      this.$store.dispatch('app/toggleNotifications');
      this.getNotifications(false);
    },
    toggleNotification(notif, key) {
      this.$store.dispatch('app/toggleNotification', key);
      this.setAsRead(notif);
    },
    toNormalDate(datetime) {
      return this.toLocalTime(datetime).format('DD.MM.yyyy HH:mm');
    },
    setAsRead(notif) {
      // return notif;
      this.$http.patch(`${process.env.VUE_APP_PUBLIC_PREFIX}/messages/${notif.id}`)
        .then(() => {
          console.log('Прочитано');
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
          }
        });
    },
    setAllAsRead() {
      this.$http.patch(`${process.env.VUE_APP_PUBLIC_PREFIX}/messages/entities`)
        .then(() => {
          this.$root.snackbar.info(this.$i18n.t('response-messages.success.success'));
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
          }
        });
    },
  },
  mounted() {
    this.getNotifications(false);

    const changeTab = (tabBlock, idx = 0) => {
      const tabBlockContents = tabBlock.querySelectorAll('.tabs-content__item');
      const tabBlockLink = tabBlock.querySelectorAll('.tabs-nav__item');

      tabBlockContents.forEach((el, index) => {
        // eslint-disable-next-line no-unused-expressions
        index !== idx ? el.classList.remove('active') : el.classList.add('active');
      });

      tabBlockLink.forEach((el, index) => {
        // eslint-disable-next-line no-unused-expressions
        index !== idx
          ? el.querySelector('.tabs-nav__link').classList.remove('active')
          : el.querySelector('.tabs-nav__link').classList.add('active');
      });
    };

    document.querySelectorAll('.tabs-nav__link').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        changeTab(el.closest('.tabs'), [...el.closest('.tabs').querySelectorAll('.tabs-nav__link')].indexOf(event.target));
      });
    });

    const tabsArr = document.querySelectorAll('.tabs');

    tabsArr.forEach((el) => {
      changeTab(el, 0);
    });
  },
};
</script>
