<template>
  <div class="page-content settings-content trade-content">
    <div class="page-content__wrapper">
      <div class="page-block">
        <div class="page-block__header">
          <p class="page-block__title">
            <router-link to="/">
              <a class="back-arrow">
                <img src="/img/white-arrow.svg">
              </a>
              {{ $t('views.options.title') }}
            </router-link>
          </p>
        </div>
        <div class="page-block__content">
          <div class="offers-item">
            <div class="settings-wrapper">
              <div class="settings-wrapper__item">
                <div class="settings-wrapper__content">
                  <div class="offers">
                    <div class="offers__wrapper">
                      <div class="offers__header">
                        <p class="offers__exchange">1 BTC ≈ 50,432 USD</p>
                      </div>
                      <div class="offers__content">
                        <div class="offers__input">
                          <input type="text" value="1000">
                          <select class="offers__select">
                            <option>USD</option>
                            <option>USD</option>
                          </select>
                        </div>
                        <div class="offers__arrow"><img src="img/offers-arrow.svg"></div>
                        <div class="offers__input">
                          <input type="text" value="1000">
                          <select class="offers__select">
                            <option>USD</option>
                            <option>USD</option>
                          </select>
                        </div>
                      </div>
                      <div class="offers__desc">
                        <div class="offers__desc-item">
                          <p class="offers__desc-title">Comission</p>
                          <p class="offers__desc-text">1% ≈ 0,00012 BTC</p>
                        </div>
                        <div class="offers__desc-item">
                          <p class="offers__desc-title">Amount</p>
                          <p class="offers__desc-text">1,45450 BTC</p>
                        </div>
                        <div class="offers__desc-item">
                          <p class="offers__desc-text">1,45450 BTC</p>
                        </div>
                      </div>
                      <div class="offers__footer">
                        <a
                          class="btn btn-yellow offers__btn popup-trigger"
                          href="#"
                          data-popup=".popup-exchange"
                        >
                          Exchange
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Options',
};
</script>
