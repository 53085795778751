<template>
  <div class="offers__input input-address-wrapper">
    <input
      v-bind="$attrs"
      :ref="unique_name"
      type="text"
      :value="value"
      @input="input"
    >
    <!-- <span class="paste-btn">Paste</span> -->
    <span class="scan-btn" @click="openScan()"></span>
    <Portal>
      <transition name="fade">
        <ScanPopup
          v-if="$store.state.app.scanPopup"
          @scan="scan"
        />
      </transition>
    </Portal>
  </div>
</template>

<script>
// import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import { Portal } from '@linusborg/vue-simple-portal'; // Портал
import ScanPopup from '@/components/popups/Scan.vue';

export default {
  name: 'InputAddress',
  props: ['value'],
  components: {
    Portal,
    ScanPopup,
  },
  data() {
    return {
      // eslint-disable-next-line no-underscore-dangle
      unique_name: `${this._uid}IA`,
    };
  },

  methods: {
    openScan() {
      this.$store.dispatch('app/toggleScanPopup');
    },
    input(e) {
      this.$emit('input', e.target.value);
    },
    scan(input) {
      this.$emit('input', input);
    },
  },
  created() {
  },
  mounted() {

  },
};
</script>

<style scoped>

</style>
