import Vue from 'vue';
import WalletList from '@/models/WalletList';

const mutations = {
  set(state, { type, value }) {
    Vue.set(state, type, value);
  },
  unset(state, { type }) {
    state[type] = null;
  },
};

const actions = {
  setFirstName({ commit }, token) {
    commit('set', { type: 'firstName', value: token });
    localStorage.setItem('firstName', token);
  },
  setLastName({ commit }, email) {
    commit('set', { type: 'lastName', value: email });
    localStorage.setItem('lastName', email);
  },
  setProfile({ commit }, data) {
    // console.log(data);
    commit('set', { type: 'firstName', value: data.verificationLevelOne?.firstName });
    commit('set', { type: 'lastName', value: data.verificationLevelOne?.lastName });
    commit('set', { type: 'phone', value: data.verificationLevelOne?.phone });
    commit('set', { type: 'lvlOneConfirmed', value: data.verificationLevelOne?.isConfirmed });
    localStorage.setItem('firstName', data.verificationLevelOne?.firstName);
    localStorage.setItem('lastName', data.verificationLevelOne?.lastName);
    localStorage.setItem('phone', data.verificationLevelOne?.phone);
    localStorage.setItem('lvlOneConfirmed', data.verificationLevelOne?.isConfirmed);

    commit('set', { type: 'register_date', value: data.created });
    commit('set', { type: 'last_visit', value: data.lastVisit });
    commit('set', { type: 'icon', value: data.icon ?? '/img/profile.svg' });
    localStorage.setItem('register_date', data.created);
    localStorage.setItem('last_visit', data.lastVisit);
    localStorage.setItem('icon', data.icon ?? '/img/profile.svg');

    commit('set', { type: 'country', value: data.location?.country });
    commit('set', { type: 'city', value: data.location?.city });
    commit('set', { type: 'address', value: data.location?.address });
    commit('set', { type: 'zip', value: data.location?.zipCode });
    localStorage.setItem('country', data.location?.country);
    localStorage.setItem('city', data.location?.city);
    localStorage.setItem('address', data.location?.address);
    localStorage.setItem('zip', data.location?.zipCode);

    commit('set', { type: 'wallets', value: (new WalletList(data.wallets)).value });
    commit('set', { type: 'mainWallets', value: (new WalletList(data.mainWallets)).value });
    localStorage.setItem('wallets', JSON.stringify(data.wallets));
    localStorage.setItem('mainWallets', JSON.stringify(data.mainWallets));

    localStorage.setItem('preferredFiatCurrency', data.preferredFiatCurrency);
    commit('set', { type: 'preferredFiatCurrency', value: data.preferredFiatCurrency });
    // localStorage.setItem('lastName', email);
  },
  logout({ commit }) {
    commit('unset', { type: 'token' });
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
  },
};

const getters = {
  fullName(state) {
    return `${state.firstName} ${state.lastName}`;
  },
};

const state = {
  firstName: localStorage.getItem('firstName') ?? '',
  lastName: localStorage.getItem('lastName') ?? '',
  phone: localStorage.getItem('phone') ?? '',
  country: localStorage.getItem('country') ?? '',
  city: localStorage.getItem('city') ?? '',
  address: localStorage.getItem('address') ?? '',
  zip: localStorage.getItem('zip') ?? '',
  lvlOneConfirmed: localStorage.getItem('lvlOneConfirmed') ?? {},
  wallets: (new WalletList(JSON.parse(localStorage.getItem('wallets')) ?? [])).value ?? [],
  mainWallets: (new WalletList(JSON.parse(localStorage.getItem('mainWallets')) ?? [])).value ?? [],
  lang: localStorage.getItem('lang') ?? process.env.VUE_APP_DEFAULT_LOCALE,

  register_date: localStorage.getItem('register_date') ?? '',
  last_visit: localStorage.getItem('last_visit') ?? '',
  icon: localStorage.getItem('icon') ?? '/img/profile.svg',
  preferredFiatCurrency: localStorage.getItem('preferredFiatCurrency') ?? 'USD',
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
