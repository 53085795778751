import Vue from 'vue';
import Vuex from 'vuex';
import profile from './profile';
import auth from './auth';
import app from './app';

Vue.use(Vuex);

const store = {
  modules: {
    auth,
    profile,
    app,
  },
  strict: true,
};

export default new Vuex.Store(store);
