<template>
  <div
    class="page-content settings-content trade-content"
    v-bind:class="{ 'full': !$store.state.app.navigation, 'not-full': $store.state.app.navigation }"
  >
    <div class="page-content__wrapper">
      <div class="page-block">
        <div class="page-block__header settings__header">
          <p class="page-block__title">Buy/Sell</p>
          <!-- <a class="btn btn-blue settings__header-btn" href="#">Become a Trader</a> -->
        </div>
        <div class="page-block__content">
          <div class="tabs profile-tabs settings-tabs">
            <div class="tabs-header">
              <div class="tabs-nav">
                <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">Buy</a></div>
                <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">Sell</a></div>
              </div>
              <div class="tabs-header__content">
                <div class="sort popup-trigger" data-popup=".popup-payment-methods">
                  <div class="sort-item">
                    <div class="sort-item__icon">
                      <svg class="icon icon-filter">
                        <use xlink:href="/img/svg/sprite.svg#filter"></use>
                      </svg>
                    </div>
                    <span class="sort-item__label">Payment Method</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-content profile-tabs__content settings-tabs__content">
              <div class="tabs-content__item">
                <div class="offers-item">
                  <div class="settings-wrapper">
                    <div class="settings-wrapper__item">
                      <div class="settings-wrapper__content">
                        <div class="offers">
                          <div class="offers__wrapper">
                            <div class="offers__header">
                              <p class="offers__exchange">1 BTC ≈ 50,432 USD</p>
                            </div>
                            <div class="offers__content">
                              <div class="offers__input">
                                <input type="text" value="1000" />
                                <select class="offers__select">
                                  <option>USD</option>
                                  <option>USD</option>
                                </select>
                              </div>
                              <div class="offers__arrow"><img src="img/offers-arrow.svg" /></div>
                              <div class="offers__input">
                                <input type="text" value="1000" />
                                <select class="offers__select">
                                  <option>USD</option>
                                  <option>USD</option>
                                </select>
                              </div>
                            </div>
                            <div class="offers__footer">
                              <a class="btn btn-yellow offers__btn" href="#">Find Offers</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="offers-item">
                  <div class="settings-wrapper">
                    <p class="settings-wrapper__title">
                      <span class="settings-wrapper__title-label">Open Deals</span
                      ><span class="sort"
                        ><span class="sort-item"
                          ><span class="sort-item__icon">
                            <svg class="icon icon-history">
                              <use xlink:href="/img/svg/sprite.svg#history"></use></svg></span
                          ><span class="sort-item__label">All Deals History</span></span
                        ></span
                      >
                    </p>
                  </div>
                  <div class="settings-tabs__content">
                    <div class="trade-content__item">
                      <div class="table table-dials swiper">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide table__wrapper">
                            <div class="table-row table-row_header table-deals__row">
                              <div class="table__column">
                                <p class="table__title">Deal</p>
                              </div>
                              <div class="table__column">
                                <p class="table__title">user</p>
                              </div>
                              <div class="table__column">
                                <p class="table__title">id</p>
                              </div>
                              <div class="table__column">
                                <p class="table__title">opened</p>
                              </div>
                              <div class="table__column">
                                <p class="table__title">status</p>
                              </div>
                            </div>
                            <div class="table-row table-row_content table-deals__row">
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__text-label"
                                    >Buy <span>0.0003328 BTC</span> / Payment
                                    <span>1,230.00 USD</span></span
                                  >
                                </p>
                              </div>
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__icon"><img src="img/chat-profile.png" /></span
                                  ><span class="table__text-label">Terminator_USA</span>
                                </p>
                              </div>
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__text-label">1234567890</span>
                                </p>
                              </div>
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__text-label">21.05.2021, 11:45</span>
                                </p>
                              </div>
                              <div class="table__column">
                                <a class="table__link" href="#">Go to Deal</a>
                              </div>
                            </div>
                            <div class="table-row table-row_content table-deals__row">
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__text-label"
                                    >Buy <span>0.0003328 BTC</span> / Payment
                                    <span>1,230.00 USD</span></span
                                  >
                                </p>
                              </div>
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__icon"><img src="img/chat-profile.png" /></span
                                  ><span class="table__text-label">Terminator_USA</span>
                                </p>
                              </div>
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__text-label">1234567890</span>
                                </p>
                              </div>
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__text-label">21.05.2021, 11:45</span>
                                </p>
                              </div>
                              <div class="table__column">
                                <p class="table__text">
                                  <span class="table__text-label status status_succes"
                                    >Deal is Close</span
                                  >
                                </p>
                              </div>
                              <div class="table__column">
                                <a class="btn btn-blue table__btn" href="#">ok</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="offers-item">
                  <div class="settings-wrapper">
                    <p class="settings-wrapper__title">
                      <span class="settings-wrapper__title-label">Open Offers</span>
                    </p>
                  </div>
                  <div class="settings-tabs__content">
                    <div class="trade-content__item">
                      <div class="table table-offer swiper">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide table__wrapper">
                            <div class="table-row table-row_header table-offer__row">
                              <div class="table-offer__column"></div>
                              <div class="table-offer__column">
                                <p class="table__title">offer</p>
                              </div>
                              <div class="table-offer__column">
                                <p class="table__title">price</p>
                              </div>
                              <div class="table-offer__column">
                                <p class="table__title">date/time</p>
                              </div>
                              <div class="table-offer__column">
                                <p class="table__title">conditions</p>
                              </div>
                              <div class="table-offer__column">
                                <p class="table__title">Payment</p>
                              </div>
                            </div>
                            <div class="table-row table-row_content table-offer__row">
                              <div class="table-offer__column table__column">
                                <p class="table__text">1/5</p>
                              </div>
                              <div class="table-offer__column table__column">
                                <p class="table__text">
                                  <span class="table__text-label">Buy BTC</span
                                  ><span class="table__icon">
                                    <svg class="icon icon-arrow-down">
                                      <use xlink:href="/img/svg/sprite.svg#arrow-down"></use></svg
                                  ></span>
                                </p>
                              </div>
                              <div class="table-offer__column table__column">
                                <p class="table__text">
                                  <span class="table__text-label"
                                    >1 BTC = 54.5445 <br />
                                    Fix</span
                                  >
                                </p>
                              </div>
                              <div class="table-offer__column table__column">
                                <p class="table__text">
                                  <span class="table__text-label"
                                    >12.12.2020 14:05 <br />
                                    23.12.2022 15:20</span
                                  >
                                </p>
                              </div>
                              <div
                                class="table-offer__column table__column table-offer__column_big"
                              >
                                <p class="table__text">
                                  <span class="table__icon">
                                    <svg class="icon icon-clock">
                                      <use xlink:href="/img/svg/sprite.svg#clock"></use></svg></span
                                  ><span class="table__text-label">2 h</span>
                                </p>
                                <p class="table__text">
                                  <span class="table__icon">
                                    <svg class="icon icon-verify">
                                      <use
                                        xlink:href="/img/svg/sprite.svg#verify"
                                      ></use></svg></span
                                  ><span class="table__text-label">Medium</span>
                                </p>
                                <p class="table__text">
                                  <span class="table__icon">
                                    <svg class="icon icon-contacts">
                                      <use xlink:href="/img/svg/sprite.svg#contacts"></use></svg
                                  ></span>
                                </p>
                                <p class="table__text">
                                  <span class="table__icon">
                                    <svg class="icon icon-passport">
                                      <use xlink:href="/img/svg/sprite.svg#passport"></use></svg
                                  ></span>
                                </p>
                              </div>
                              <div
                                class="table-offer__column table__column table-offer__column_big"
                              >
                                <ul class="table-payment">
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/skrill.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/paypal.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/swift.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/qiwi.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/transferWise.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/payonner.svg" />
                                  </li>
                                </ul>
                              </div>
                              <div class="table-offer__column table__column">
                                <a class="table__link" href="#">Go to Offer</a>
                              </div>
                              <div class="table-offer__column table__column">
                                <a class="table__icon" href="#">
                                  <svg class="icon icon-delete-red">
                                    <use xlink:href="/img/svg/sprite.svg#delete-red"></use></svg
                                ></a>
                              </div>
                            </div>
                            <div class="table-row table-row_content table-offer__row">
                              <div class="table-offer__column table__column">
                                <p class="table__text">1/5</p>
                              </div>
                              <div class="table-offer__column table__column">
                                <p class="table__text">
                                  <span class="table__text-label">Buy BTC</span
                                  ><span class="table__icon">
                                    <svg class="icon icon-arrow-down">
                                      <use xlink:href="/img/svg/sprite.svg#arrow-down"></use></svg
                                  ></span>
                                </p>
                              </div>
                              <div class="table-offer__column table__column">
                                <p class="table__text">
                                  <span class="table__text-label"
                                    >1 BTC = 54.5445 <br />
                                    Fix</span
                                  >
                                </p>
                              </div>
                              <div class="table-offer__column table__column">
                                <p class="table__text">
                                  <span class="table__text-label"
                                    >12.12.2020 14:05 <br />
                                    23.12.2022 15:20</span
                                  >
                                </p>
                              </div>
                              <div
                                class="table-offer__column table__column table-offer__column_big"
                              >
                                <p class="table__text">
                                  <span class="table__icon">
                                    <svg class="icon icon-clock">
                                      <use xlink:href="/img/svg/sprite.svg#clock"></use></svg></span
                                  ><span class="table__text-label">2 h</span>
                                </p>
                                <p class="table__text">
                                  <span class="table__icon">
                                    <svg class="icon icon-verify">
                                      <use
                                        xlink:href="/img/svg/sprite.svg#verify"
                                      ></use></svg></span
                                  ><span class="table__text-label">Medium</span>
                                </p>
                                <p class="table__text">
                                  <span class="table__icon">
                                    <svg class="icon icon-contacts">
                                      <use xlink:href="/img/svg/sprite.svg#contacts"></use></svg
                                  ></span>
                                </p>
                              </div>
                              <div
                                class="table-offer__column table__column table-offer__column_big"
                              >
                                <ul class="table-payment">
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/skrill.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/paypal.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/swift.svg" />
                                  </li>
                                  <li class="table-payment__item">
                                    <img class="table-payment__img" src="img/qiwi.svg" />
                                  </li>
                                </ul>
                              </div>
                              <div class="table-offer__column table__column">
                                <a class="table__link" href="#">Go to Offer</a>
                              </div>
                              <div class="table-offer__column table__column">
                                <a class="table__icon" href="#">
                                  <svg class="icon icon-delete-red">
                                    <use xlink:href="/img/svg/sprite.svg#delete-red"></use></svg
                                ></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuySell',
  methods: {
    changeTab(tabBlock, idx = 0) {
      const tabBlockContents = tabBlock.querySelectorAll('.tabs-content__item');
      const tabBlockLink = tabBlock.querySelectorAll('.tabs-nav__item');

      tabBlockContents.forEach((el, index) => {
        if (index !== idx) {
          el.classList.remove('active');
        } else {
          el.classList.add('active');
        }
      });

      tabBlockLink.forEach((el, index) => {
        if (index !== idx) {
          el.querySelector('.tabs-nav__link').classList.remove('active');
        } else {
          el.querySelector('.tabs-nav__link').classList.add('active');
        }
      });
    },
  },
  mounted() {
    const tabsArr = document.querySelectorAll('.tabs');

    tabsArr.forEach((el) => {
      this.changeTab(el, 0);
    });

    document.querySelectorAll('.tabs-nav__link').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        this.changeTab(
          el.closest('.tabs'),
          [...el.closest('.tabs').querySelectorAll('.tabs-nav__link')].indexOf(event.target),
        );
      });
    });

    // eslint-disable-next-line no-undef
    const tableSlider = new Swiper('.table', {
      direction: 'horizontal',
      slidesPerView: 'auto',
      freeMode: true,
      spaceBetween: 0,
      mousewheel: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    });

    // eslint-disable-next-line no-undef
    const msgSlider = new Swiper('.chat-wrapper__content', {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      spaceBetween: 0,
      mousewheel: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    });

    console.log(msgSlider, tableSlider);
  },
};
</script>
