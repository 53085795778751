<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ value[value_label] }}
      <img class="" src="img/arrow-down.svg" style="margin: 0 10px;width: 10px;">
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="option of options"
        :key="unique_key_prefix + option[value_key]"
        :class="{ 'selected': option[value_key] === value[value_key] }"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option[value_label] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value_key: {
      type: String,
      required: true,
      default: null,
    },
    value_label: {
      type: String,
      required: true,
      default: null,
    },
    value: {
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.options.length > 0
        ? this.options[0]
        : null,
      unique_key_prefix: '',
      open: false,
    };
  },
  methods: {
    make_prefix() {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    },
  },
  watch: {
    options: {
      handler() {
        if (!this.options.some((option) => option[this.value_key] == this.selected[this.value_key])) {
          [this.selected] = this.options;
        }
      },
    },
  },
  mounted() {
    this.unique_key_prefix = this.make_prefix();
    // this.$emit('input', this.selected);
  },
  created() {
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
}

.custom-select .selected {
  color: #fff;
  cursor: pointer;
  user-select: none;
  display: flex;
}
.custom-select .selected img {
  margin: 0 10px;
  width: 10px;
}
.custom-select .selected.open {}

.custom-select .items {
  position: absolute;
  top: 30px;
  z-index: 9;
  background: #1E5BA4;
  -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 16px;
  padding-bottom: 0;
  left: -8px;
}

.custom-select .items .selected {
  color: #006aeb;
}

.custom-select .items div {
  font-size: 16px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 400;
  padding-bottom: 16px;
}

.custom-select .items div:hover {
  background: transparent;
  color: #329DFF;
}

.selectHide {
  display: none;
}
</style>
