import Vue from 'vue';

const mutations = {
  set(state, { type, value }) {
    Vue.set(state, type, value);
  },
  unset(state, { type }) {
    state[type] = null;
  },
  toggle(state, { type }) {
    state[type] = !state[type];
  },
  toggleShow(state, { type }) {
    Vue.set(state[type], 'show', !state[type]?.show ?? true);
  },
  toggleEl(state, { type, index }) { // Спец toggle для уведомлений
    // 😎
    Vue.set(state[type][index], 'active', !state[type][index]?.active ?? true);
  },
};

const actions = {
  toggleNavigation({ commit }) {
    commit('toggle', { type: 'navigation' });
  },
  // Открыть / закрыть список уведомлений
  toggleNotifications({ commit }) {
    commit('toggle', { type: 'notifications_show' });
  },
  // Свернутть / развернуть конкретное уведомление
  toggleNotification({ commit }, index) {
    commit('toggleEl', { type: 'notifications', index });
  },
  toggleChangePassword({ commit }) {
    commit('toggle', { type: 'popupChangePassword' });
  },
  toggleTradeCollapsed({ commit }) {
    commit('toggle', { type: 'tradeCollapsed' });
  },
  toggleMainCollapsed({ commit }) {
    commit('toggle', { type: 'mainCollapsed' });
  },
  setWalletForRecieve({ commit }, data) {
    commit('set', { type: 'recieveWallet', value: data });
  },
  setCurrencies({ commit }, data) {
    commit('set', { type: 'currencies', value: data });
  },
  setNewWallet({ commit }, data) {
    commit('set', { type: 'newWallet', value: data });
  },
  setLoading({ commit }, data) {
    commit('set', { type: 'loading', value: data });
  },
  setNotifications({ commit }, data) {
    commit('set', { type: 'notifications', value: data.reverse() });
  },
  setPaymentData({ commit }, data) {
    commit('set', { type: 'paymentData', value: data });
    localStorage.setItem('paymentData', JSON.stringify(data));
  },
  setUniPopup({ commit }, data) {
    commit('set', { type: 'uniPopup', value: data });
  },
  toggleUniPopup({ commit }) {
    commit('toggleShow', { type: 'uniPopup' });
  },
  toggleScanPopup({ commit }) {
    commit('toggle', { type: 'scanPopup' });
  },
  setScanAddress({ commit }, data) {
    commit('set', { type: 'lastAddressScan', value: data });
  },
  setSocket({ commit }, data) {
    commit('set', { type: 'socket', value: data });
  },
};

const getters = {
  navigation(state) {
    return state.navigation;
  },
};

const state = {
  navigation: true, // Боковое навигационное меню
  loading: false, // Загружаются ли данные с API
  newWallet: '', // Тип нового кошелька (открывает попап)
  currencies: [], // Доступные валюты
  recieveWallet: {}, // Кошелек на которые хотим получить перевод (открывает попап)
  notifications: [], // Уведомления
  notifications_show: false, // Показ окна уведомлений
  popupChangePassword: false, // попап. Смена пароля
  // languages: [], // Доступные языки
  paymentData: JSON.parse(localStorage.getItem('paymentData')) ?? {}, // Информация по валютам
  uniPopup: {}, // Универсальный попап
  socket: {}, // вебсокет
  tradeCollapsed: false,
  mainCollapsed: false,
  scanPopup: false,
  lastAddressScan: '',
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
