<script>
import { create, all } from 'mathjs';
import moment from 'moment';

const math = create(all, {
  number: 'BigNumber',
  precision: 8,
});

export default {
  name: 'Helper',
  data() {
    return {
      math,
    };
  },
  methods: {
    routerPush(path, params) { // Переход по роутам с проверкой текущего роута
      if (this.$route.path === path) {
        return false;
      }
      this.$router.push({ path }, params);
      return true;
    },
    async logout(skipApiCall = true) { // Разлогин
      if (!skipApiCall) {
        await this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/logout`).then(() => {
          this.$root.snackbar.info(this.$i18n.t('response-messages.success.logout'));
        });
      }
      this.$store.dispatch('auth/logout');
      delete this.$http.defaults.headers.common.Authorization;
      if (this.$store.state.app?.socket?.readyState) {
        this.$store.state.app.socket.close();
      }
      this.$router.push({ path: '/login' });

      return true;
    },
    async newWallet(walletType, name, block = true) {
      if (block) this.$store.dispatch('app/setLoading', true);
      let returnData;
      await this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${walletType}/${name}/account`, { walletType, name })
        .then((response) => {
          returnData = response.data;
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
          }
        }).then(() => {
          if (block) {
            this.$store.dispatch('app/setLoading', false);
          }
        });
      return returnData;
    },
    async getProfile(block = true) {
      if (block) this.$store.dispatch('app/setLoading', true);
      let returnData;
      await this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile`)
        .then((response) => {
          returnData = response;
          this.$store.dispatch('profile/setProfile', response.data);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        }).then(() => {
          if (block) {
            this.$store.dispatch('app/setLoading', false);
          }
        });
      return returnData;
    },
    async getPaymentData() {
      let returnData;
      await this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/general/payment_data`)
        .then((response) => {
          returnData = response;
          this.$store.dispatch('app/setPaymentData', response.data);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        });
      return returnData;
    },
    async getNotifications(block = false) {
      if (block) this.$store.dispatch('app/setLoading', true);
      let returnData;
      await this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/messages/WALLET`)
        .then((response) => {
          this.$store.dispatch('app/setNotifications', response.data);
          returnData = response;
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
          }
        }).then(() => {
          if (block) this.$store.dispatch('app/setLoading', false);
        });
      return returnData;
    },
    async getCurrencyInfo(load = true) {
      this.loading = load;
      let returnData;
      await this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/exchange`)
        .then((response) => {
          this.exchange_info = response.data;
          returnData = response.data;
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        }).then(() => {
          if (load) {
            this.loading = false;
          }
          // setTimeout(() => this.getCurrencyInfo(false), 1000 * 60);
        });
      return returnData ?? false;
    },
    inputNumber(input) {
      return input;
    },
    // Округление до len после запятой в меньшую сторону (либо если toLower == false - в большую)
    toFixed(value, len = 8, toLower = true) {
      if (!value) { return 0; }
      let length = len;
      if (len > 8) {
        length = 8;
      }
      if (toLower) {
        // Извабвляемся от числа 'e', от лишних нулей, округляем в меньшую сторону
        // return math.format(value, { notation: 'fixed', precision: len });
        return (Math.floor(value * (10 ** length)) / (10 ** length)).toFixed(length).replace(/\.?0*$/, '');
      }
      return parseFloat(value.toFixed(length));
    },
    onlyNumber(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode === 46) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    TRIDOwn() {
      let wallets = [...this.$store.state.profile.wallets, ...this.$store.state.profile.mainWallets];
      wallets = wallets.filter((wallet) => wallet.currency === 'TRIDENT');
      const own = wallets.reduce((sum, wallet) => wallet.value + sum, 0);
      return own / 20000000;
    },
    getWalletIcon(currency) {
      return this.$store.state.app.paymentData.walletList.filter(
        (wallet) => wallet.currency === currency,
      )[0]?.icon ?? '/img/DefLogoBig.png';
    },
    toLocalTime(string) {
      return moment.utc(string).local();
      // const tz = moment.tz.guess();
      // return moment(string).to;
    },
    getBlockchainInfo(currency) {
      const walletList = [...this.$store.state.profile.wallets, ...this.$store.state.profile.mainWallets];
      const info = walletList.find((wal) => wal.currency == currency)?.blockchainSettings;
      return info;
    },
  },
  mounted() {
  },
};
</script>

<template>
<div></div>
</template>
