import Big from 'big.js';
import BlockchainSettings from './BlockchainSettings';

const MAX_FLOAT_NUM = 8;

/**
 * @property {array} accounts
 * @property {BlockchainSettings} blockchainSettings
 * @property {boolean} currency
 * @property {number} value
 * @property {number} rate
 * @property {string} walletType
 */
export default class Wallet {
  accounts = []

  constructor(data) {
    this.accounts = data?.accounts ?? [];
    this.blockchainSettings = new BlockchainSettings(data?.blockchainSettings);
    this.currency = data?.currency ?? '';
    this.currencyName = data?.currencyName ?? '';
    this.value = data?.value ?? 0;
    this.rate = data?.rate ?? 0;
    this.walletType = data?.walletType ?? '';
  }

  validateInput(event) {
    const input = String.fromCharCode(event.which);
    const stringValue = event.target.value.toString();

    let arrayValue = [];
    if (stringValue.includes('.')) {
      arrayValue = stringValue.split('.');
    }
    if (stringValue.includes(',')) {
      arrayValue = stringValue.split(',');
    }

    if ((arrayValue.length > 1) && !(arrayValue[1].length <= this.floatLength() + 1)) {
      return false;
    }

    if (!input.match(/[0-9]|.|,/)) {
      return false;
    }
    return input;
  }

  getFirstAddress() {
    return this.accounts[0];
  }

  isToken() {
    return this.blockchainSettings.typeCryptoCurrency.includes('TOKEN');
  }

  get addresses() {
    const accounts = [];
    this.accounts.forEach((account) => accounts.push(account.address));
    return accounts;
  }

  get isEthOrToken() {
    return (this.isToken()
      || (this.blockchainSettings.typeCryptoCurrency == 'CURRENCY' && this.blockchainSettings.blockchainNetwork.includes('ETH'))
    );
  }

  floatLength() {
    return (Math.log10(this.blockchainSettings.unit) > MAX_FLOAT_NUM)
      ? MAX_FLOAT_NUM
      : Math.log10(this.blockchainSettings.unit);
  }

  get actualCurrency() {
    return this.isToken() ? this.blockchainSettings.basicCurrency : this.currency;
  }

  filterValue(value) {
    return new Big(value || '0').toPrecision(this.floatLength());
    // console.log(value, floatLength);
    // return value;
    // return new Big(value).toPrecision(floatLength);
  }
}
