<template>
  <div class="popup popup-paid" style="z-index: 9999; opacity: 1; overflow: auto;">
    <div class="popup__wrapper">
      <div class="popup__header">
        <p class="popup__title">
          <span v-html="current_template.title"></span>
        </p>
        <div class="popup__close popup-close" @click="close()">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.93468 4.93468 0 11 0C17.0653 0 22 4.93468 22 11C22 17.0653 17.0653 22 11 22C4.93468 22 0 17.0653 0 11ZM13.4955 15.3104L15.3104 13.4955L12.8149 11L15.3104 8.50452L13.4955 6.68962L11 9.18511L8.5045 6.68962L6.6896 8.50452L9.18509 11L6.6896 13.4955L8.5045 15.3104L11 12.8149L13.4955 15.3104Z" fill="#297BC7"/>
          </svg>
        </div>
      </div>
      <div class="popup_icon_wrapper" v-if="current_template.icon">
        <img class="popup_icon" :src="current_template.icon" alt="">
      </div>

      <div class="popup-paid__content">
        <!-- <p class="popup-paid__title" v-html="templates[0].title">Are you sure you made the transfer?</p> -->
        <p class="popup-paid__desc" v-html="current_template.text"></p>
      </div>
      <div
        class="popup-paid__footer"
        :style="`grid-template-columns: repeat(${current_template.buttons.length}, minmax(0, 1fr))`"
      >
        <a
          v-for="button in current_template.buttons"
          :key="button.text"
          :class="`${button.class} btn`"
          v-html="button.text"
          @click="button.action"
        >
        </a>
        <!-- <a class="btn-yellow btn" href="#">Yes, I`m Sure</a> -->
      </div>
    </div>
  </div>
</template>

<script>
// Компонент позволяет "на лету" собрать попап и показать его пользователю
export default {
  name: 'UniPopup',
  data() {
    return {
      // Тут можно сделать шаблоны для попапов которые в системе будут по-умолчанию
      templates: {
        default: {
          icon: '',
          title: '',
          text: '',
          buttons: [],
        },
        recieve_noaddress: {
          icon: '',
          title: 'Information',
          text: `For third party applications, the token can only be received in your Private wallet. <br/><br/>
            For token transactions, the token is transferred from the Private wallet to the Trading wallet.`,
          buttons: [
            // {
            //   class: 'btn-blue',
            //   text: 'cancel',
            //   action: () => { this.$store.dispatch('app/toggleUniPopup'); },
            // },
            {
              class: 'btn-yellow',
              text: 'OK',
              action: () => { this.$store.dispatch('app/toggleUniPopup'); },
            },
          ],
        },
      },
    };
  },
  methods: {
    close() {
      this.$store.dispatch('app/toggleUniPopup');
    },
  },
  computed: {
    current_template() {
      if (this.$store.state.app.uniPopup?.template) {
        // Можно использовать шаблон, как пресет, и дополнять уже сверху своими данными
        return { ...this.templates[this.$store.state.app.uniPopup.template], ...this.$store.state.app.uniPopup };
      }
      return { ...this.templates.default, ...this.$store.state.app.uniPopup };
    },
  },
  mounted() {},
};
</script>

<style scoped>
  .popup_icon_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .popup_icon {
    width: 108px;
  }
</style>
