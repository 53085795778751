<template>
  <div class="wallet-card">
    <div class="wallet-card__wrapper">
      <div class="wallet-card__content head_content">
        <div class="wallet-card__img wallet-card__img_desktop">
          <img :src="getWalletIcon(wallet.currency)">
        </div>
        <div class="wallet-card__img wallet-card__img_mobile">
          <img :src="getWalletIcon(wallet.currency)">
        </div>
        <div class="wallet-card__info">
          <div class="wallet-card__header">
            <p class="wallet-card__name">
              <span>{{ wallet.currencyName }}</span>
            </p>
            <!-- <div class="wallet-card__remove" v-if="canRemove">
              <a class="wallet-card__remove-link clickable" @click="destroyWallet">
                <div class="wallet-card__remove-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 17C4 18.1 4.9 19 6 19H14C15.1 19 16 18.1 16 17V5H4V17ZM17 2H13.5L12.5 1H7.5L6.5 2H3V4H17V2Z"
                      fill="white"
                      fill-opacity="0.2"
                    />
                  </svg></div
                ></a>
            </div> -->
          </div>
          <div class="wallet-card__exchange">
              <div class="wallet-card__exchange-info">
                <span class="wallet-card__exchange-one">
                  {{ wallet.rate.toFixed(2) }} {{ $store.state.profile.preferredFiatCurrency }}
                </span>
              </div>
              <!-- <div class="wallet-card__exchange-trend trend">
                <div class="trend__wrapper">
                  <div class="trend__icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3392 5.43259L10.1986 6.5432C10.1208 6.61921 9.9966 6.619 9.919 6.5428L7.99961 4.6608V12.8C7.99961 12.9104 7.91001 13 7.79962 13H6.19961C6.08922 13 5.99962 12.9104 5.99962 12.8V4.6608L4.08023 6.5428C4.00262 6.61881 3.87862 6.619 3.80083 6.5432L2.66022 5.43259C2.62143 5.395 2.59961 5.34339 2.59961 5.28939C2.59961 5.23539 2.62162 5.18378 2.66022 5.14619L6.86022 1.05679C6.89901 1.01898 6.94942 1 6.99963 1C7.05002 1 7.10022 1.01901 7.13922 1.05679L11.3392 5.14619C11.3778 5.18378 11.3996 5.23539 11.3996 5.28939C11.3996 5.34339 11.3778 5.395 11.3392 5.43259Z"
                        fill="#66FF66"
                      />
                    </svg>
                  </div>
                  <span class="trend__label">+ 00.0%</span>
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div class="wallet-card__content">
        <div class="wallet-card__info">
          <div class="wallet-card__header count_address_data">
            <div class="wallet-card__address" v-if="showAddress">
              <a
                class="wallet-card__address-select"
              >
                {{ wallet.getFirstAddress().address }}
              </a>
              <!-- <select
                class="wallet-card__address-select"
                style="-webkit-appearance: initial;"
                v-model='currentAddress'
                v-if="showAddress"
              >
                <option
                  v-for="address in wallet.addresses"
                  :key="address"
                  :value="address"
                >
                  {{ address }}
                </option>
              </select> -->
              <!-- <div
                class="wallet-card__address-control"
                v-if="showAddress"
              >
                <a
                  class="wallet-card__address-link clickable"
                  @click="toClipboard(currentAddress)"
                >
                  <svg class="icon icon-copy">
                    <use xlink:href="/img/svg/sprite.svg#copy"></use>
                  </svg>
                </a>
                <a
                  class="wallet-card__address-link clickable"
                  v-if="
                    wallet.blockchainSettings.blockchainNetwork !== 'ETH'
                    && !wallet.blockchainSettings.blockchainNetwork.includes('TOKEN')
                  "
                  @click="newAddress()"
                >
                  <svg class="icon icon-add">
                    <use xlink:href="/img/svg/sprite.svg#add"></use>
                  </svg>
                </a>
              </div> -->
            </div>
            <div class="wallet-card__count" v-if="!full">
              <router-link :to="{ name: 'WalletHistory', params: { walletType: wallet.walletType , currency: wallet.currency }}">
                <a
                  class="wallet-card__count-link clickable"
                >
                  {{ toFixed(wallet.value, 4) }} {{ wallet.currency }}
                </a>
              </router-link>
            </div>
            <div class="wallet-card__count" v-if="full">
              <a
                class="wallet-card__count-nolink"
              >{{ toFixed(wallet.value, 8) }} {{ wallet.currency }}</a
              >
            </div>
          </div>
          <div class="wallet-card__exchange">
              <div class="wallet-card__exchange-info">
                <span class="wallet-card__exchange-all">
                  ~{{ toFixed(wallet.rate * wallet.value, 2) }} {{ $store.state.profile.preferredFiatCurrency }}
                </span>
              </div>
              <a
                class="wallet-card__exchange-link"
                v-bind:class="{ 'disabled': wallet.value == 0, 'clickable':wallet.value > 0 }"
                v-if="wallet.walletType == 'trade'"
                @click="payoutToMain()"
              >
                Send to Private
              </a>
              <a
                class="wallet-card__exchange-link"
                v-bind:class="{ 'disabled': wallet.value == 0, 'clickable':wallet.value > 0 }"
                v-if="wallet.walletType == 'main'"
                @click="payoutToTrade()"
              >
                Send to Trade
              </a>

              <!-- <div class="wallet-card__exchange-trend trend">
                <div class="trend__wrapper">
                  <div class="trend__icon">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3392 5.43259L10.1986 6.5432C10.1208 6.61921 9.9966 6.619 9.919 6.5428L7.99961 4.6608V12.8C7.99961 12.9104 7.91001 13 7.79962 13H6.19961C6.08922 13 5.99962 12.9104 5.99962 12.8V4.6608L4.08023 6.5428C4.00262 6.61881 3.87862 6.619 3.80083 6.5432L2.66022 5.43259C2.62143 5.395 2.59961 5.34339 2.59961 5.28939C2.59961 5.23539 2.62162 5.18378 2.66022 5.14619L6.86022 1.05679C6.89901 1.01898 6.94942 1 6.99963 1C7.05002 1 7.10022 1.01901 7.13922 1.05679L11.3392 5.14619C11.3778 5.18378 11.3996 5.23539 11.3996 5.28939C11.3996 5.34339 11.3778 5.395 11.3392 5.43259Z"
                        fill="#66FF66"
                      />
                    </svg>
                  </div>
                  <span class="trend__label">+ 00.0%</span>
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div class="wallet-card__footer" v-bind:class="{ 'three': wallet.walletType == 'trade'}">

        <!-- <a
          class="btn btn-blue wallet-card__btn btn-disabled"
          v-if="wallet.walletType == 'trade'"
          @click="exchange()"
        >
          <span class="wallet-card__btn-icon">
            <svg class="icon icon-exchange"><use xlink:href="/img/svg/sprite.svg#buy"></use></svg>
          </span>
          <span class="wallet-card__btn-text">{{ $t('components.wallet-card.buy') }}</span>
        </a>

        <a
          class="btn btn-blue wallet-card__btn btn-disabled"
          v-if="wallet.walletType == 'trade'"
          @click="exchange()"
        >
          <span class="wallet-card__btn-icon" style="width: 35%;">
            <svg class="icon icon-exchange"><use xlink:href="/img/svg/sprite.svg#sell"></use></svg>
          </span>
          <span class="wallet-card__btn-text">{{ $t('components.wallet-card.sell') }}</span>
        </a> -->

        <a
          class="btn btn-blue wallet-card__btn"
          v-if="wallet.walletType == 'trade'"
          v-bind:class="{ 'btn-disabled': wallet.value == 0, 'clickable':wallet.value > 0 }"
          @click="exchange()"
        >
          <span class="wallet-card__btn-icon">
            <svg class="icon icon-exchange"><use xlink:href="/img/svg/sprite.svg#exchange"></use></svg>
          </span>
          <span class="wallet-card__btn-text">{{ $t('components.wallet-card.exchange') }}</span>
        </a>

        <!-- <a
          class="btn btn-blue wallet-card__btn"
          v-bind:class="{ 'btn-disabled': wallet.walletType !== 'main', 'clickable':wallet.walletType === 'main' }"
          @click="getSecretKey()"
          v-if="wallet.walletType == 'main'"
        >
          <span class="wallet-card__btn-icon icon-lock">
            <img src="/img/svg/lock.svg" class="icon icon-buy"/>
          </span>
          <span class="wallet-card__btn-text">{{ $t('components.wallet-card.secret-key') }}</span>
        </a> -->

        <!-- <a
          class="btn btn-blue wallet-card__btn"
          style="grid-column: 2 / 4; grid-row: 1 / 2;"
          v-bind:class="{ 'btn-disabled': wallet.value == 0, 'clickable':wallet.value > 0 }"
          v-if="wallet.walletType == 'main'"
          @click="payoutToTrade()"
        >
          <span class="wallet-card__btn-icon pay-out__btn">
            <img src="/img/svg/icon_pay_out.svg" class="icon icon-sell" style="margin-right: 5px;">
            Trade
          </span>
          <span class="wallet-card__btn-text">{{ $t('components.wallet-card.send-to-trade') }}</span>
        </a> -->

        <a
          class="btn btn-blue wallet-card__btn"
          v-bind:class="{ 'btn-disabled': wallet.value == 0, 'clickable':wallet.value > 0 }"
          @click="payout()"
        >
          <span class="wallet-card__btn-icon">
            <svg class="icon icon-payout"><use xlink:href="/img/svg/sprite.svg#payout"></use></svg>
          </span>
          <span class="wallet-card__btn-text">{{ $t('components.wallet-card.payout') }}</span>
        </a>

        <a
          class="btn btn-yellow wallet-card__btn"
          @click="recieve()"
        >
          <span class="wallet-card__btn-icon">
            <svg class="icon icon-payout" style="transform: rotate(180deg)"><use xlink:href="/img/svg/sprite.svg#payout"></use></svg>
          </span>
          <span class="wallet-card__btn-text">{{ $t('components.wallet-card.receive') }}</span>
        </a>

      </div>
    </div>
  </div>
</template>

<script>
import Wallet from '@/models/Wallet';

export default {
  name: 'WalletCard',
  props: {
    wallet: {
      type: Wallet,
    },
    full: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      currentAddress: this.wallet.getFirstAddress().address,
    };
  },
  methods: {
    toClipboard(data) {
      navigator.clipboard.writeText(data).then(() => {
        this.$root.snackbar.info(this.$i18n.t('components.wallet-card.clipboard-saved'));
      }, () => {
        this.$root.snackbar.warn(this.$i18n.t('components.wallet-card.clipboard-problem'));
      });
    },
    newAddress() {
      const data = {
        name: this.wallet.currency,
        walletType: this.wallet.walletType,
      };

      this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/address`, data)
        .then(() => {
          this.$root.snackbar.info('Address created!');
          this.getProfile(false);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              case 403:
                this.$root.snackbar.warn(error.response.data);
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        });
    },
    destroyWallet() {
      if (this.wallet.value !== 0) {
        this.$root.snackbar.warn(this.$i18n.t('components.wallet-card.wallet-remove-problem'));
        return false;
      }

      this.$http.delete(
        `${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${this.wallet.walletType}/${this.wallet.currency}`,
      )
        .then(() => {
          this.getProfile(true);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        });
      return true;
    },
    payout() {
      this.$router.push({
        name: 'PayOut',
        params: {
          wallet_from: this.wallet,
        },
      });
    },
    payoutToTrade() {
      if (this.$store.state.profile.wallets.filter((el) => el.currency === this.wallet.currency).length) {
        this.$router.push({
          name: 'PayOut',
          params: {
            wallet_from: this.wallet,
            wallet_to: this.$store.state.profile.wallets.filter((el) => el.currency === this.wallet.currency)[0]?.addresses[0],
          },
        });
      } else {
        const popupData = { show: true, title: 'Send to Trade', text: `You dont have ${this.wallet.currency} Trade wallet` };
        popupData.buttons = [{
          text: `Create ${this.wallet.currency} Trade Wallet`,
          class: 'btn-yellow',
          action: () => {
            this.newWallet('trade', this.wallet.currency, true)
              .then(() => this.getProfile(true).then(() => {
                this.$router.push({
                  name: 'PayOut',
                  params: {
                    wallet_from: this.wallet,
                    wallet_to: this.$store.state.profile.wallets.filter((el) => el.currency === this.wallet.currency)[0]?.addresses[0],
                  },
                });
              }));
            this.$store.dispatch('app/toggleUniPopup');
          },
        }];
        this.$store.dispatch('app/setUniPopup', popupData);
      }
    },
    payoutToMain() {
      if (this.$store.state.profile.mainWallets.filter((el) => el.currency === this.wallet.currency).length) {
        this.$router.push({
          name: 'PayOut',
          params: {
            wallet_from: this.wallet,
            wallet_to: this.$store.state.profile.mainWallets.filter((el) => el.currency === this.wallet.currency)[0]?.addresses[0],
          },
        });
      } else {
        const popupData = { show: true, title: 'Send to Private', text: `You dont have ${this.wallet.currency} Private wallet` };
        popupData.buttons = [{
          text: `Create ${this.wallet.currency} Private Wallet`,
          class: 'btn-yellow',
          action: () => {
            this.newWallet('main', this.wallet.currency, true)
              .then(() => this.getProfile(true).then(() => {
                this.$router.push({
                  name: 'PayOut',
                  params: {
                    wallet_from: this.wallet,
                    wallet_to: this.$store
                      .state.profile
                      .mainWallets.filter((el) => el.currency === this.wallet.currency)[0]
                      ?.getFirstAddress().address,
                  },
                });
              }));
            this.$store.dispatch('app/toggleUniPopup');
          },
        }];
        this.$store.dispatch('app/setUniPopup', popupData);
      }
    },
    async getSecretKey() {
      await this.$http.get(
        `${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${this.wallet.currency}?addrs=${this.currentAddress}`,
      )
        .then((response) => {
          let text = '';
          /* eslint-disable-next-line */
          for (const [key, value] of Object.entries(response.data)) {
            if (value != null) {
              text = `${text}${key}: ${value}\n`;
            }
          }
          this.makeFile(`${this.wallet.currency}-key(Trident-guard).txt`, text);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        });
    },
    makeFile(filename, text) {
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    exchange() {
      // console.log(this.wallet);
      this.$router.push({
        name: 'Exchange',
        params: {
          wallet_from: this.wallet,
        },
      });
    },
    toHistory() {
      this.$router.push({
        name: 'WalletHistory',
        params: {
          wallet: this.wallet,
        },
      });
    },
    recieve() {
      if (this.canRecieve) {
        this.$store.dispatch('app/setWalletForRecieve', this.wallet);
      } else {
        const popupData = { show: true, template: 'recieve_noaddress' };
        if (this.$store.state.profile.mainWallets.filter((wallet) => wallet.currency == this.wallet.currency).length == 0) {
          popupData.buttons = [{
            text: `Open ${this.wallet.currency} Private Wallet`,
            class: 'btn-yellow',
            action: () => {
              this.newWallet('main', this.wallet.currency, false)
                .then(() => this.getProfile(false));
              this.$store.dispatch('app/toggleUniPopup');
            },
          }];
        }
        this.$store.dispatch('app/setUniPopup', popupData);
      }
    },
  },
  computed: {
    valueInFiat() {
      if (this.wallet.valueInPreferredFiatCurrency === 0 || this.wallet.value === 0) return 0;
      return (this.wallet.valueInPreferredFiatCurrency / this.wallet.value).toFixed(2);
    },
    showAddress() {
      return (this.wallet.blockchainSettings.typeCryptoCurrency == 'CURRENCY' || this.wallet.walletType == 'main')
        && this.wallet.accounts.length;
    },
    canRecieve() {
      return this.showAddress || this.wallet.walletType == 'main';
      /* this.$store.state.profile.mainWallets.filter((wallet) => wallet.currency === this.wallet.currency)?.length */
    },
    canRemove() {
      return !(this.wallet.blockchainSettings.blockchainNetwork === 'ETH' && this.wallet.currency !== 'ETH');
    },
  },
  mounted() {
    // console.log(this.wallet.addresses[0]);
    // eslint-disable-next-line prefer-destructuring
    // this.currentAddress = this.wallet.addresses[0];
    // this.$set(this, 'currentAddress', this.wallet.addresses[0]);
  },
  created() {
    // this.$set(this, 'currentAddress', this.wallet.addresses[0]);
  },
};
</script>

<style>
.disabled {
  pointer-events: none;
  color: gray;
}

.wallet-card__count-nolink {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #fff;
    margin: 8px 0 12px 0;
}

/* .icon-lock {
  height: 30px;
} */

@media screen and (max-width: 475px) {
  .wallet-card__count-nolink {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

</style>
