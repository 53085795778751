import Vue from 'vue';
import App from '@/App.vue';
import router from '@/plugins/router'; // Контроль роутов
import store from '@/plugins/store'; // Хранилище
import i18n from '@/plugins/langs/index'; // Многоязычность
import http from '@/plugins/http'; // Запросы
import '@/plugins/snackbar'; // Snackbar
import helper from '@/mixins/Helper.vue';

import VueQRCodeComponent from 'vue-qr-generator';

Vue.component('qr-code', VueQRCodeComponent);

// Vue.config.globalProperties.window = window;

Vue.config.productionTip = false;

Vue.mixin(helper);

new Vue({
  router,
  store,
  i18n,
  http,
  render: (h) => h(App),
}).$mount('#app');
