<template>
  <!-- <div class="wallet-card "> -->
    <div class="wallet-card__wrapper wallet-card__wrapper__empty clickable">
      <div class="wallet-empty">
        <div class="wallet-empty__wrapper">
          <div class="wallet-empty__icon">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M60 34.2857H34.2857V60H25.7143V34.2857H0V25.7143H25.7143V0H34.2857V25.7143H60V34.2857Z" fill="#114179"/>
            </svg>
          </div>
          <p class="wallet-empty__title">{{ $t('components.wallet-add-card.title') }}</p>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'WalletAddCard',
  props: ['type'],
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {},
};
</script>
