<template>
  <div id="app">
    <router-view/>
    <snackbar
      ref="snackbar"
      baseSize="100px"
      :wrapClass="''"
      :colors="{open: '#333',info: '#3DBD7D',error: '#FA7377',warn: '#FF6600'}"
      :holdTime="3000"
      :multiple="true"
    />
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {

  },
  created() {
    // К какому API обращаемся ( обьявлен в .env.local )
    this.$http.defaults.baseURL = process.env.VUE_APP_ROOT_URL;

    if (
      !this.$store.state.auth.token
      && this.$store.state.auth.token !== 'null'
      && this.$route.path !== '/login'
      && this.$route.path !== '/forgot'
      && this.$route.path !== '/registration'
      && this.$route.path !== '/activate'
    ) {
      console.log('Redirect to login...');
      this.$router.push('/login');
    } else if (
      this.$route.path !== '/login'
      && this.$route.path !== '/forgot'
      && this.$route.path !== '/registration'
      && this.$route.path !== '/activate'
    ) {
      this.$http.defaults.headers.common.Authorization = this.$store.state.auth.token;
    }

    // Тут проверка, если токен просрочен или неверен TODO: Вынести 401
    // this.$http.interceptors.response.use((response) => {
    //   if (response) { // TODO: условие
    //     // console.log(response);
    //     if (response.status === 401) {
    //       this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
    //       this.logout();
    //     }
    //   }
    // },
    // (error) => Promise.reject(error));

    // Страницы для которых не нужна авторизация
    this.$router.beforeEach((to, from, next) => {
      if (
        to.path !== '/login'
        && to.path !== '/registration'
        && to.path !== '/forgot'
        && to.path !== '/activate'
        && !this.$store.state.auth.token
        && this.$store.state.auth.token !== 'null'
      ) {
        // Если нет логина
        next({ name: 'login' });
      }
      next();
    });
  },
  mounted() {
    // Делаем snackbar глобальный доступ
    this.$root.snackbar = this.$refs.snackbar;
  },
};
</script>
