import Wallet from './Wallet';

export default class WalletList {
  // data = null

  constructor(data) {
    this.wallets = [];
    data.forEach((wallet) => {
      this.wallets = [...this.wallets, new Wallet(wallet)];
    });
  }

  get value() {
    return this.wallets;
  }
}
