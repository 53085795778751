<template>
  <div
    class="page-content settings-content trade-content"
    v-bind:class="{ 'full': !$store.state.app.navigation, 'not-full': $store.state.app.navigation }"
  >
    <div class="page-content__wrapper">
      <div class="page-block">
        <div class="page-block__header settings__header">
          <p class="page-block__title">
            <router-link to="/">
              <a class="back-arrow">
                <img src="/img/white-arrow.svg">
              </a>
              {{ $t('views.payout.title') }}
            </router-link>
          </p>
          <!-- <a class="btn btn-blue settings__header-btn" href="#">Become a Trader</a> -->
        </div>
        <div class="page-block__content">
          <div class="tabs profile-tabs settings-tabs">
            <div class="tabs-content profile-tabs__content settings-tabs__content">
              <div class="tabs-content__item">
                <div class="offers-item">
                  <div class="settings-wrapper">
                    <div class="settings-wrapper__item">
                      <div class="settings-wrapper__content">
                        <div class="offers">
                          <div class="offers__wrapper">
                            <div class="offers__content">
                              <div class="offers__input">
                                <InputCryptoValue
                                  type="number"
                                  placeholder="00.00"
                                  v-model="amount"
                                  :wallet="wallet"
                                  @input="getCommission()"
                                />
                                <div
                                  class="clickable max_value__input table_block value__input_desktop"
                                  @click="pasteFull()"
                                >
                                  Max {{ max }}
                                </div>
                                <span class="offers__select">
                                  {{ wallet.currency }}
                                </span>
                              </div>
                              <div
                                class="max_value__input clickable value__input_mobile"
                                style="margin-bottom: 20px;"
                                @click="pasteFull()"
                              >
                                  <span>
                                    Max {{ max }}
                                  </span>
                              </div>
                              <!-- <div class="rate_info_block">
                                <div class="offers__input rate_info" v-if="realCurrency == 'ETH'">
                                ≈ {{ (1 / (ethWallet.rate * 1000)).toFixed(2) }} {{ realCurrency }}
                                </div>
                                <div
                                  class="clickable max_value__input mobile_block"
                                  @click="pasteFull()"
                                >
                                  Max {{ max }}
                                </div>
                              </div> -->
                              <InputAddress
                                placeholder="Wallet address"
                                v-model="address"
                                @input="getCommission()"
                              />
                              <div v-if="!$store.state.app.loading">
                                <div class="offers_param">
                                  <div style="margin-right: 20px">{{ $t('views.payout.commission') }}</div>
                                  <div class="white">{{ fee_data.systemWalletCommission }} {{ wallet.currency }}</div>
                                </div>
                                <div class="offers_param">
                                  <div style="margin-right: 20px">{{ $t('views.payout.network-fee') }}</div>
                                  <div class="white">{{ fee_data.feesCommission }} {{ wallet.actualCurrency }}</div>
                                </div>
                                <div class="offers_param">
                                  <div style="margin-right: 20px">{{ $t('views.payout.total') }}</div>
                                  <div class="white" v-bind:class="{'red':(max < amount)}">
                                    <template>
                                      {{ total }} {{ wallet.currency }}
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <LoadingSpinner
                              v-if="$store.state.app.loading"
                              style="margin: 50px 0px;"
                            />
                            <div class="offers__footer">
                              <a
                                class="btn btn-yellow offers__btn"
                                v-bind:class="{ 'btn-disabled':!activeConfirm, 'clickable':activeConfirm }"
                                v-if="!submitting"
                                @click="confirm()"
                              >
                                Pay Out
                              </a>
                              <LoadingSpinner
                                v-if="submitting"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import InputAddress from '@/components/ui/InputAddress.vue';
import InputCryptoValue from '@/components/ui/InputCryptoValue.vue';
import Wallet from '@/models/Wallet';

export default {
  name: 'PayOut',
  data() {
    return {
      wallet: new Wallet(this.$route.params?.wallet_from ?? {}),
      amount: 0,
      address: '',
      outputAddress: '',
      fee_data: {
        systemWalletCommission: 0,
        totalCommission: 0,
        feesCommission: 0,
      },
      submitting: false,
    };
  },
  methods: {
    changeTab(tabBlock, idx = 0) {
      const tabBlockContents = tabBlock.querySelectorAll('.tabs-content__item');
      const tabBlockLink = tabBlock.querySelectorAll('.tabs-nav__item');

      tabBlockContents.forEach((el, index) => {
        if (index !== idx) {
          el.classList.remove('active');
        } else {
          el.classList.add('active');
        }
      });

      tabBlockLink.forEach((el, index) => {
        if (index !== idx) {
          el.querySelector('.tabs-nav__link').classList.remove('active');
        } else {
          el.querySelector('.tabs-nav__link').classList.add('active');
        }
      });
    },
    pasteFull() {
      this.amount = this.max;
      // this.getProfile(false);
      this.getCommission(false);
    },
    confirmDo() {
      this.submitting = true;

      const data = {
        outputAddress: this.outputAddress,
        amount: this.amount/*  - this.fee_data.feesCommission */,
        // totalCommission: 0.0000001, // for test
        feesCommission: this.fee_data.feesCommission,
      };

      this.$http.post(
        `${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${this.wallet.walletType}/${this.wallet.currency}/transfer`,
        data,
      )
        .then(() => {
          this.getProfile(true);
          this.$root.snackbar.info(this.$i18n.t('views.payout.transfer-complete'));
          this.$router.push({ path: '/' });
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              case 403:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.exchange-commision'));
                this.getCommission();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            console.log(error);
          }
        })
        .then(() => {
          this.submitting = false;
        });
    },
    confirm() {
      const popupData = { show: true };
      popupData.title = 'Confirm payout';
      popupData.text = `${this.amount} ${this.wallet.currency} to ${this.address}`;
      popupData.buttons = [{
        text: 'Confirm',
        class: 'btn-yellow',
        action: () => {
          this.confirmDo();
          this.$store.dispatch('app/toggleUniPopup');
        },
      }];
      this.$store.dispatch('app/setUniPopup', popupData);
    },
    getCommission: _.debounce(function () {
      if (this.amount > 0 && this.address.length > 0) {
        // this.$store.dispatch('app/setLoading', true);

        const data = {
          outputAddress: this.address,
          amount: this.amount,
        };

        this.$http.post(
          `${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${this.wallet.walletType}/${this.wallet.currency}/commission`,
          data,
        )
          .then((response) => {
            this.outputAddress = response.data.outputAddress;
            this.fee_data = response.data;
            this.wallet.value = response.data.walletBalance;
          })
          .catch((error) => {
            this.outputAddress = '';
            this.fee_data = {
              systemWalletCommission: 0,
              totalCommission: 0,
              feesCommission: 0,
            };
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                  this.logout();
                  break;
                case 403:
                  this.$root.snackbar.warn('Not enough money for transfer');
                  break;
                default:
                  // this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
              }
              console.log(error);
            }
          }).then(() => {
            // this.$store.dispatch('app/setLoading', false);
          });

        // this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile`)
        //   .then((response) => {
        //     this.$store.dispatch('profile/setProfile', response.data);
        //     this.$store.dispatch('app/setCurrencies', response.data.availableCurrencies);
        //     get();
        //   })
        //   .catch((error) => {
        //     if (error.response) {
        //       switch (error.response.status) {
        //         case 401:
        //           this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
        //           this.logout();
        //           break;
        //         default:
        //           this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
        //       }
        //       // console.log(error);
        //     }
        //   }).then(() => {
        //     this.$store.dispatch('app/setLoading', false);
        //   });
      }
    }, 300),
    preCommission() {
      let testAddress;
      switch (this.wallet.currency) {
        case 'BTC':
          testAddress = 'bc1q994lpmzhl3j9e0uzn5clcrurm3lzyev4j7jrws';
          break;
        case 'ETH':
          testAddress = '0x481991a24006ffBA17E34Ecdae8959a745434Eab';
          break;
        // case 'TRID':
        //   testAddress = '0x481991a24006ffBA17E34Ecdae8959a745434Eab';
        case 'LTC':
          testAddress = 'ltc1qppv3rzck8gh43c073ej302dpkzk7gqr8h3j5d8';
          break;
        case 'BCH':
          testAddress = 'qr48lxwjheeyurxr5a6lmsvlw9tc3y7znu3jp3v2v8';
          break;
        case 'DOGE':
          testAddress = 'DCEuxFt6pJeFqgLYxcTKXy5cyCNn9Cq7Mn';
          break;
        default:
      }
      if (!testAddress) return;

      const data = {
        address: testAddress,
        amount: 0.00000001,
      };

      this.$http.post(
        `${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${this.wallet.walletType}/${this.wallet.currency}/commission`,
        data,
      )
        .then((response) => {
          // this.outputAddress = response.data.address;
          this.fee_data = response.data;
        })
        .catch((error) => {
          this.outputAddress = '';
          this.fee_data = {
            systemWalletCommission: 0,
            totalCommission: 0,
            feesCommission: 0,
          };
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                console.log('?????');
            }
          }
        }).then(() => {
          this.$store.dispatch('app/setLoading', false);
        });
    },
    numbers(event) {
      const txt = String.fromCharCode(event.which);
      if (!txt.match(/[0-9]/)) {
        event.preventDefault();
      }
    },
    input(evt) {
      if (evt.target.value < 0) {
        this.amount_from = evt.target.value * -1;
      } else {
        this.amount_from = evt.target.value;
      }
    },
  },
  computed: {
    activeConfirm() {
      return this.address === this.outputAddress
        && this.outputAddress !== ''
        && !this.$store.state.app.loading
        && this.amount <= this.max
        && (!this.wallet.isToken()
          || (this.fee_data.feesCommission <= this.actualWallet.value
          && this.wallet.isToken())
        );
    },
    actualWallet() {
      return this.wallet.walletType == 'trade'
        ? this.$store.state.profile.wallets.filter((wal) => wal.currency == 'ETH')[0]
        : this.$store.state.profile.mainWallets.filter((wal) => wal.currency == 'ETH')[0];
    },
    total() {
      if (this.wallet.isToken()) {
        return this.toFixed(this.math.add(this.fee_data.systemWalletCommission, this.amount));
      }
      return this.toFixed(this.math.add(this.math.add(this.fee_data.systemWalletCommission, this.amount), this.fee_data.feesCommission));
    },
    // isToken() {
    //   return this.wallet?.blockchainSettings.typeCryptoCurrency?.includes('TOKEN')
    //     || this.wallet?.blockchainSettings.blockchainNetwork?.includes('ETH');
    // },
    max() {
      let value = 0;
      if (!this.wallet?.value) return 0;
      if (this.wallet.isToken()) {
        value = this.toFixed(this.wallet.value - this.fee_data.systemWalletCommission, this.wallet.floatLength());
        return value;
      }
      value = this.toFixed(
        this.math.subtract(
          this.math.subtract(
            this.wallet.value, this.fee_data.feesCommission,
          ),
          this.fee_data.systemWalletCommission,
        ),
        this.wallet.floatLength(),
      );
      return value >= 0 ? value : 0;
    },
  },
  watch: {
    amount() {
      this.getCommission();
    },
  },
  components: {
    LoadingSpinner,
    InputAddress,
    InputCryptoValue,
  },
  mounted() {
    // console.log(this.$route.params?.wallet_to);
    if (this.$route.params?.wallet_from == null) {
      this.$router.push({ path: '/' });
    }

    if (this.$route.params?.wallet_to) {
      this.address = this.$route.params?.wallet_to;
    }

    this.wallet = new Wallet(this.$route.params.wallet_from);
    this.preCommission();

    const tabsArr = document.querySelectorAll('.tabs');

    tabsArr.forEach((el) => {
      this.changeTab(el, 0);
    });

    document.querySelectorAll('.tabs-nav__link').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        this.changeTab(
          el.closest('.tabs'),
          [...el.closest('.tabs').querySelectorAll('.tabs-nav__link')].indexOf(event.target),
        );
      });
    });
  },
  created() {
    // console.log(this.property);
  },
};
</script>

<style>
.red {
  color: red;
}
.max_value__input {
  border-right: 2px solid #14386E;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 14px;
}
.rate_info_block .max_value__input {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
  text-align: center;
}
.offers__content {
  margin-top: 0;
}
.offers__content > .offers__input {
  border: 1px solid #297BC7;
  margin-bottom: 20px;
}
.offers__input input {
  font-size: 16px;
}
.rate_info_block {
  margin-bottom: 20px;
  text-align: center;
}
.rate_info {
  color: #bebebe;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: unset;
  min-height: unset;
  margin-bottom: 10px;
  padding: 0;
}
</style>
