const mutations = {
  set(state, { type, value }) {
    state[type] = value;
  },
  unset(state, { type }) {
    state[type] = null;
  },
};

const actions = {
  setToken({ commit }, token) {
    commit('set', { type: 'token', value: token });
    localStorage.setItem('token', token);
  },
  setEmail({ commit }, email) {
    commit('set', { type: 'email', value: email });
    localStorage.setItem('email', email);
  },
  // Храним пароль только во время процесса регистрации
  setPassword({ commit }, password) {
    commit('set', { type: 'password', value: password });
    localStorage.setItem('password', password);
  },
  logout({ commit }) {
    commit('unset', { type: 'token' });
    localStorage.removeItem('token');
    localStorage.removeItem('email');
  },
};

const getters = {
  token(state) {
    return state.token;
  },
};

const state = {
  token: localStorage.getItem('token'),
  email: localStorage.getItem('email'),
  password: '',
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
