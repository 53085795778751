<template>
  <div class="popup popup-qr" style="z-index: 9999; opacity: 1; overflow: auto;">
    <div class="popup__wrapper">
      <div class="popup__header">
        <p class="popup__title"><span>Scan your QR</span></p>
        <div
          class="popup__close popup-close"
          @click="$store.dispatch('app/toggleScanPopup')"
        >
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 11C0 4.93468 4.93468 0 11 0C17.0653 0 22 4.93468 22 11C22 17.0653 17.0653 22 11 22C4.93468 22 0 17.0653 0 11ZM13.4955 15.3104L15.3104 13.4955L12.8149 11L15.3104 8.50452L13.4955 6.68962L11 9.18511L8.5045 6.68962L6.6896 8.50452L9.18509 11L6.6896 13.4955L8.5045 15.3104L11 12.8149L13.4955 15.3104Z"
              fill="#297BC7"
            />
          </svg>
        </div>
      </div>
      <div class="popup__content">
        <div class="popup-qr">
          <qrcode-stream @decode="onDecode"></qrcode-stream>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'Scan',
  data() {
    return {
      address: '',
    };
  },
  components: {
    QrcodeStream,
  },
  methods: {
    onDecode(string) {
      this.$emit('scan', string);
      this.$store.dispatch('app/setScanAddress', string);
      this.$store.dispatch('app/toggleScanPopup');
    },
  },
  mounted() {
    // this.$store.dispatch('app/setBodyScroll', false);
  },
  destroyed() {
    // this.$store.dispatch('app/setBodyScroll', true);
  },
};
</script>
