<!-- eslint-disable max-len -->
<!-- Поскольку тут много inline-svg, то игнорирую правило на максимальную длину строки у линтера -->
<template>
  <aside
    class="left-aside"
    v-bind:class="{ 'hide':$store.state.app.navigation }"
  >
    <div class="left-aside__wrapper">
      <div class="left-aside__block left-aside__block_first">
        <div class="profile aside-profile">
          <div class="profile__wrapper">
            <div class="profile__img aside-profile__img">
              <div class="profile__img-item aside-profile__img-item">
                <img :src="$store.state.profile.icon" />
              </div>
              <div class="profile__img-verify aside-profile__img-verify">
                <img src="/img/verification.svg" />
              </div>
            </div>
            <div class="profile__link aside-profile__links">
              <a @click="toggleNavigation(true)"><router-link to="/settings/profile">{{ login }}</router-link></a>
              <span
                class="trid-owner-mobile"
                v-bind:class="{ 'has-trid': TRIDOwn() }"
              >
                Owner: {{ TRIDOwn() > 0 ? TRIDOwn().toFixed(6) : TRIDOwn() }}%
              </span>
              <a class="aside-profile__link clickable" @click="logout(false)">Log out</a>
            </div>
            <a class="aside-profile__settings" @click="toggleNavigation(true)">
              <router-link to="/settings/profile">
                <img src="/img/settings.svg" />
              </router-link>
            </a>
          </div>
        </div>
      </div>
      <div class="left-aside__block">
        <ul class="left-aside__list page-list">
          <li
            class="page-list__item"
            v-bind:class="{ 'page-list__item_current': $route.path == '/' }"
          >
            <a
              class="page-list__link clickable"
              @click="toggleNavigation(true)"
            >
              <router-link to="/">
                <span class="page-list__link-wrapper">
                  <span class="page-list__link-icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.0526 15.2105V16.1579C18.0526 17.2 17.2 18.0526 16.1579 18.0526H2.89474C1.84316 18.0526 1 17.2 1 16.1579V2.89474C1 1.85263 1.84316 1 2.89474 1H16.1579C17.2 1 18.0526 1.85263 18.0526 2.89474V3.84211H9.52632C8.47474 3.84211 7.63158 4.69474 7.63158 5.73684V13.3158C7.63158 14.3579 8.47474 15.2105 9.52632 15.2105H18.0526ZM9.52632 13.3158H19V5.73684H9.52632V13.3158ZM13.3158 10.9474C12.5295 10.9474 11.8947 10.3126 11.8947 9.52632C11.8947 8.74 12.5295 8.10526 13.3158 8.10526C14.1021 8.10526 14.7368 8.74 14.7368 9.52632C14.7368 10.3126 14.1021 10.9474 13.3158 10.9474Z"
                        fill="#297BC7"
                      />
                    </svg>
                    <span
                      v-if="false"
                      class="notification-counter notification__counter page-list-notification__counter"
                    >
                    0
                    </span>
                  </span>
                    <span class="page-list__link-text">{{ $t('components.navigation.wallet') }}</span>
                </span>
              </router-link>
            </a>
          </li>
          <li
            class="page-list__item"
            v-bind:class="{ 'page-list__item_current': $route.path == '/exchange' }"
          >
            <a
              class="page-list__link clickable"
              @click="toggleNavigation(true)"
            >
              <router-link to="/exchange">
                <span class="page-list__link-wrapper">
                  <span class="page-list__link-icon">

                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.75 8.49999V5.51406C1.75 3.83675 2.75918 2.95269 4.67387 2.95269H14.8701V1.26394C14.8701 1.15915 14.9433 1.06262 15.0557 1.01943C15.1702 0.980688 15.2986 0.999741 15.3856 1.07786L18.9119 4.17399C19.0294 4.27688 19.0294 4.44518 18.9119 4.54743L15.3856 7.64801C15.2993 7.72486 15.171 7.74836 15.0564 7.70581C14.944 7.66579 14.8708 7.56862 14.8708 7.46066V5.54645H5.19659C4.93052 5.54645 4.7152 5.73698 4.7152 5.97134V8.49999H1.75Z"
                        fill="#297BC7"
                      />
                      <path
                        d="M18.25 11.5V14.4859C18.25 16.1632 17.2408 17.0473 15.3261 17.0473H5.12989V18.7361C5.12989 18.8408 5.05667 18.9374 4.94429 18.9806C4.82975 19.0193 4.70142 19.0003 4.61442 18.9221L1.08809 15.826C0.970638 15.7231 0.970638 15.5548 1.08809 15.4526L4.61442 12.352C4.7007 12.2751 4.82902 12.2516 4.94357 12.2942C5.05594 12.3342 5.12917 12.4314 5.12917 12.5393V14.4535H14.8034C15.0695 14.4535 15.2848 14.263 15.2848 14.0287V11.5H18.25Z"
                        fill="#297BC7"
                      />
                    </svg>
                    <span
                      v-if="false"
                      class="notification-counter notification__counter page-list-notification__counter"
                    >
                    0
                    </span>
                  </span>
                  <span class="page-list__link-text">{{ $t('components.navigation.exchange') }}</span>
                </span>
              </router-link>
            </a>
          </li>
          <!-- <li
            class="page-list__item"
            v-bind:class="{ 'page-list__item_current': $route.path == '/buy-sell' }"
          >
            <a
              class="page-list__link clickable"
              @click="toggleNavigation(true)"
            >
              <router-link to="/buy-sell">
                <span class="page-list__link-wrapper">
                  <span class="page-list__link-icon">

                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.7273 11.129V4.62581C14.7273 3.73161 13.9909 3 13.0909 3H2.63636C1.73636 3 1 3.73161 1 4.62581V11.129C1 12.0232 1.73636 12.7548 2.63636 12.7548H13.0909C13.9909 12.7548 14.7273 12.0232 14.7273 11.129ZM7.54545 10.3161C6.18727 10.3161 5.09091 9.22684 5.09091 7.87742C5.09091 6.528 6.18727 5.43871 7.54545 5.43871C8.90364 5.43871 10 6.528 10 7.87742C10 9.22684 8.90364 10.3161 7.54545 10.3161ZM19 6.43226V15.3742C19 16.2684 18.2636 17 17.3636 17H3.45455V15.3742H17.3636V6.43226H19Z"
                        fill="#297BC7"
                      />
                    </svg>
                    <span
                      v-if="false"
                      class="notification-counter notification__counter page-list-notification__counter"
                    >
                    0
                    </span>
                  </span>
                  <span class="page-list__link-text">{{ $t('components.navigation.sell-buy') }}</span>
                </span>
              </router-link>
            </a>
          </li> -->
          <!-- <li
            v-for="el in filtered_menu"
            v-bind:key="el.title"
            class="page-list__item"
            v-bind:class="{ 'page-list__item_current': $route.path == el.path }"
          >
            <a
              class="page-list__link clickable"
              @click="$route.path != el.path ? $router.push({ path: el.path }) : false"
            >
              <span class="page-list__link-wrapper">
                <span class="page-list__link-icon">

                  <div v-html="el.icon"></div>
                  <span
                    v-if="el.notifications"
                    class="notification-counter notification__counter page-list-notification__counter"
                  >
                    {{ el.notifications }}
                  </span>
                </span>
                  <span class="page-list__link-text">{{ el.title }}</span></span
              ></a
            >
          </li> -->
          <!-- <li
            class="page-list__item"
            v-bind:class="{ 'page-list__item_current': $route.path == '/exchange' }"
          >
            <a
              class="page-list__link"
              href="#"
              @click="$route.path != '/exchange' ? $router.push({ path: '/exchange' }) : false"
              ><span class="page-list__link-wrapper"
                ><span class="page-list__link-icon"
                  ><svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.75 8.49999V5.51406C1.75 3.83675 2.75918 2.95269 4.67387 2.95269H14.8701V1.26394C14.8701 1.15915 14.9433 1.06262 15.0557 1.01943C15.1702 0.980688 15.2986 0.999741 15.3856 1.07786L18.9119 4.17399C19.0294 4.27688 19.0294 4.44518 18.9119 4.54743L15.3856 7.64801C15.2993 7.72486 15.171 7.74836 15.0564 7.70581C14.944 7.66579 14.8708 7.56862 14.8708 7.46066V5.54645H5.19659C4.93052 5.54645 4.7152 5.73698 4.7152 5.97134V8.49999H1.75Z"
                      fill="#297BC7"
                    />
                    <path
                      d="M18.25 11.5V14.4859C18.25 16.1632 17.2408 17.0473 15.3261 17.0473H5.12989V18.7361C5.12989 18.8408 5.05667 18.9374 4.94429 18.9806C4.82975 19.0193 4.70142 19.0003 4.61442 18.9221L1.08809 15.826C0.970638 15.7231 0.970638 15.5548 1.08809 15.4526L4.61442 12.352C4.7007 12.2751 4.82902 12.2516 4.94357 12.2942C5.05594 12.3342 5.12917 12.4314 5.12917 12.5393V14.4535H14.8034C15.0695 14.4535 15.2848 14.263 15.2848 14.0287V11.5H18.25Z"
                      fill="#297BC7"
                    /></svg></span
                ><span class="page-list__link-text">Exchange</span></span
              ></a
            >
          </li>
          <li class="page-list__item">
            <a class="page-list__link" href="#"
              ><span class="page-list__link-wrapper"
                ><span class="page-list__link-icon"
                  ><svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7273 11.129V4.62581C14.7273 3.73161 13.9909 3 13.0909 3H2.63636C1.73636 3 1 3.73161 1 4.62581V11.129C1 12.0232 1.73636 12.7548 2.63636 12.7548H13.0909C13.9909 12.7548 14.7273 12.0232 14.7273 11.129ZM7.54545 10.3161C6.18727 10.3161 5.09091 9.22684 5.09091 7.87742C5.09091 6.528 6.18727 5.43871 7.54545 5.43871C8.90364 5.43871 10 6.528 10 7.87742C10 9.22684 8.90364 10.3161 7.54545 10.3161ZM19 6.43226V15.3742C19 16.2684 18.2636 17 17.3636 17H3.45455V15.3742H17.3636V6.43226H19Z"
                      fill="#297BC7"
                    /></svg></span
                ><span class="page-list__link-text">Buy / Sell</span></span
              ></a
            >
          </li>
          <li class="page-list__item">
            <a class="page-list__link" href="#"
              ><span class="page-list__link-wrapper"
                ><span class="page-list__link-icon"
                  ><svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4036 17.9451V6.09103H18.648L14.7061 1L10.7642 6.09103H13.0086V17.9451H11.7184V8.15635H8.32341V17.9451H7.03814V11.2765H3.64311V17.9451H1V19H19.0033V17.9451H16.4036Z"
                      fill="#297BC7"
                    /></svg></span
                ><span class="page-list__link-text">Options</span></span
              ></a
            >
          </li> -->
        </ul>
      </div>
      <div class="left-aside__block left-aside__block_third">
        <ul class="left-aside__list page-list">
          <li class="page-list__item">
            <a class="page-list__link clickable"
              @click="toggleNotifications()"
              ><span class="page-list__link-wrapper"
                ><span class="page-list__link-icon"
                  ><svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7692 38C21.8 38 23.4615 36.3385 23.4615 34.3077H16.0769C16.0769 36.3385 17.72 38 19.7692 38ZM30.8461 26.9231V17.6923C30.8461 12.0246 27.8185 7.28 22.5385 6.02462V4.76923C22.5385 3.23692 21.3015 2 19.7692 2C18.2369 2 17 3.23692 17 4.76923V6.02462C11.7015 7.28 8.69231 12.0062 8.69231 17.6923V26.9231L5 30.6154V32.4615H34.5385V30.6154L30.8461 26.9231Z"
                      fill="#297BC7"
                    />
                  </svg></span
                ><span class="page-list__link-text">Notifications</span></span
              ></a
            >
          </li>
        </ul>
        <!-- <div class="aside-languages">
          <p class="aside-languages__label">Language</p>
          <div class="language-select language-nav-select">
            <select>
              <option>en</option>
              <option>ru</option>
            </select>
          </div>
        </div> -->
      </div>
    </div>
  </aside>
</template>

<script>
import customSelect from 'custom-select';

export default {
  name: 'Navigation',
  data() {
    return {
      menu: [
        { /* Опционы пока не нужны */
          enable: false,
          path: '/options',
          title: 'Options',
          notifications: 0,
          icon: `<svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4036 17.9451V6.09103H18.648L14.7061 1L10.7642 6.09103H13.0086V17.9451H11.7184V8.15635H8.32341V17.9451H7.03814V11.2765H3.64311V17.9451H1V19H19.0033V17.9451H16.4036Z"
                      fill="#297BC7"
                    /></svg>`,

        },
      ],
      login: this.$store.state.auth.email,
    };
  },
  // computed: {
  //   filtered_menu() {
  //     return this.menu.filter((el) => el.enable);
  //   },
  // },
  methods: {
    toggleNavigation(onlyMobile = false) {
      if (onlyMobile) {
        if (window.innerWidth < 990) {
          this.$store.dispatch('app/toggleNavigation');
        }
      }
    },
    toggleNotifications() {
      this.$store.dispatch('app/toggleNavigation');
      this.$store.dispatch('app/toggleNotifications');
    },
  },
  created() {
  },
  mounted() {
    if (window.innerWidth < 990 && !this.$store.state.app.navigation) {
      this.$store.dispatch('app/toggleNavigation');
    }
    customSelect('.language-select.language-nav-select select');
    // const burger = document.querySelector('.burger');
    // const mainNav = document.querySelector('.left-aside');
    // const pageContent = document.querySelector('.page-content');

    // burger.addEventListener('click', function () {
    //   this.classList.toggle('hide');
    //   mainNav.classList.toggle('hide');
    //   pageContent.classList.toggle('full');
    //   document.querySelector('body').classList.toggle('no-scroll-mob');
    //   document.querySelector('html').classList.toggle('no-scroll-mob');
    // });
  },
};
</script>

<style scoped>
.trid-owner-mobile {
  margin-top: 10px;
  color: rgb(209, 209, 209);
}

.aside-profile__settings {
  top: unset !important;
  bottom: 0;
}

.has-trid {
  color: rgb(255, 255, 96);
}
</style>
