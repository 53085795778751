<template>
  <div
    class="page-content"
    v-bind:class="{ 'full': !$store.state.app.navigation, 'not-full': $store.state.app.navigation }"
  >
    <div class="page-content__wrapper">
      <div class="page-block">
        <div class="page-block__header">
          <p class="page-block__title">{{ $t('views.home.title') }}</p>
          <div class="custom-awesome-select">
            {{ ($store.state.profile.wallets.reduce(
                  (sum, curr) => curr.rate * curr.value + sum,
                  0)
                + $store.state.profile.mainWallets.reduce(
                  (sum, curr) => curr.rate * curr.value + sum,
                  0)).toFixed(2) }}&nbsp;
            <select v-model="current_currency">
              <option
                v-for="currency in $store.state.app.paymentData.currencyFiatList"
                :key="currency"
                :value="currency"
              >
                {{ currency }}
              </option>
            </select>
          </div>
        </div>
        <div class="page-block__content">
          <div class="wallet-block">
            <div class="wallet-block__header">
              <div class="wallet-block__title">
                <span>{{ $t('views.home.wallet-main') }}</span>
                <div class="help-block" @click="toggleHelpMain()">
                  <div class="help-block__wrapper">
                    <div class="help-block__icon">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 15.3H8.1V13.5H9.9V15.3ZM11.763 8.325L10.953 9.153C10.305 9.81 9.9 10.35 9.9 11.7H8.1V11.25C8.1 10.26 8.505 9.36 9.153 8.703L10.269 7.569C10.602 7.245 10.8 6.795 10.8 6.3C10.8 5.31 9.99 4.5 9 4.5C8.01 4.5 7.2 5.31 7.2 6.3H5.4C5.4 4.311 7.011 2.7 9 2.7C10.989 2.7 12.6 4.311 12.6 6.3C12.6 7.092 12.276 7.812 11.763 8.325Z"
                          fill="#297BC7"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="help-block__content" v-show="main_help_show">
                    <p class="help-block__text" v-html="$t('views.home.help-text-main')">
                    </p>
                  </div>
                </div>
              </div>
              <div class="wallet-block__select">
                <span>
                {{ $store.state.profile.mainWallets.reduce(
                  (sum, curr) => curr.rate * curr.value + sum,
                  0).toFixed(2)
                  }} {{ current_currency }}
                </span>
                <div class="wallet-block__folder" @click="collapseMain()">
                  <img src="img/arrow-down-blue.svg" v-bind:class="{ 'collapsed':$store.state.app.mainCollapsed }"/>
                </div>
              </div>
            </div>
            <div class="wallet-block__content" v-if="!$store.state.app.mainCollapsed">
              <div class="wallet-grid" v-if="!$store.state.app.loading">
                <WalletCard
                  v-for="wallet in $store.state.profile.mainWallets"
                  :key="wallet.currency + wallet.walletType"
                  :wallet="wallet"
                />
                <WalletAddCard
                  v-if="$store.state.profile.mainWallets.length < $store.state.app.currencies.length"
                  type="main"
                  @click.native="newWallet('main')"
                />
              </div>
              <div class="wallet-grid" v-if="$store.state.app.loading">
                <LoadingSpinner/>
              </div>
            </div>
          </div>
          <div class="wallet-block">
            <div class="wallet-block__header">
              <div class="wallet-block__title">
                <span>{{ $t('views.home.wallet-trade') }}</span>
                <div class="help-block" @click="toggleHelpTrade()">
                  <div class="help-block__wrapper">
                    <div class="help-block__icon">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 15.3H8.1V13.5H9.9V15.3ZM11.763 8.325L10.953 9.153C10.305 9.81 9.9 10.35 9.9 11.7H8.1V11.25C8.1 10.26 8.505 9.36 9.153 8.703L10.269 7.569C10.602 7.245 10.8 6.795 10.8 6.3C10.8 5.31 9.99 4.5 9 4.5C8.01 4.5 7.2 5.31 7.2 6.3H5.4C5.4 4.311 7.011 2.7 9 2.7C10.989 2.7 12.6 4.311 12.6 6.3C12.6 7.092 12.276 7.812 11.763 8.325Z"
                          fill="#297BC7"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="help-block__content" v-show="trade_help_show">
                    <p class="help-block__text" v-html="$t('views.home.help-text-trade')">
                    </p>
                  </div>
                </div>
              </div>
              <div class="wallet-block__select">
                <span>
                {{ $store.state.profile.wallets.reduce(
                  (sum, curr) => curr.rate * curr.value + sum,
                  0).toFixed(2) }} {{ current_currency }}
                </span>
                <div class="wallet-block__folder" @click="collapseTrade()">
                  <img src="img/arrow-down-blue.svg" v-bind:class="{ 'collapsed':$store.state.app.tradeCollapsed }"/>
                </div>
              </div>
            </div>
            <div class="wallet-block__content"  v-if="!$store.state.app.tradeCollapsed">
              <div class="wallet-grid" v-if="!$store.state.app.loading">
                <WalletCard
                  v-for="wallet in $store.state.profile.wallets"
                  :key="wallet.currency + wallet.walletType"
                  :wallet="wallet"
                />
                <WalletAddCard
                  v-if="$store.state.profile.wallets.length < $store.state.app.currencies.length"
                  type="trade"
                  @click.native="newWallet('trade')"
                />
              </div>
              <div class="wallet-grid" v-if="$store.state.app.loading">
                <LoadingSpinner/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customSelect from 'custom-select';

import WalletCard from '@/components/WalletCard.vue';
import WalletAddCard from '@/components/WalletAddCard.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'Home',
  data() {
    return {
      trade_help_show: false,
      main_help_show: false,
    };
  },
  components: {
    WalletCard,
    WalletAddCard,
    LoadingSpinner,
  },
  methods: {
    newWallet(type) {
      this.$store.dispatch('app/setNewWallet', type);
    },
    collapseMain() {
      this.$store.dispatch('app/toggleMainCollapsed');
    },
    collapseTrade() {
      this.$store.dispatch('app/toggleTradeCollapsed');
    },
    toggleHelpTrade() {
      this.trade_help_show = !this.trade_help_show;
    },
    toggleHelpMain() {
      this.main_help_show = !this.main_help_show;
    },
  },
  computed: {
    current_currency: {
      get() {
        return this.$store.state.profile.preferredFiatCurrency;
      },
      set(value) {
        // FIXME: обьект пока нельзя так передавать
        this.$http.put(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile`, { preferredFiatCurrency: value })
          .then(() => {
            this.$root.snackbar.info(this.$i18n.t('response-messages.success.success'));
            this.getProfile();
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                  this.logout();
                  break;
                default:
                  this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
              }
            }
          });
      },
    },
  },
  created() {
  },
  mounted() {
    customSelect('.custom-awesome-select select');
  },
};
</script>

<style>
.page-block  {
  max-width: 1650px;
}
.currency-select {
  background: none;
  outline: none;
  border: none;
  font-size: 34px;
  font-weight: 500;
  color: #fff;
}
</style>
