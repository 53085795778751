<template>
    <div class="popup popup-currency" style="z-index: 9999; opacity: 1; overflow: auto;">
      <div class="popup__wrapper">
        <div class="popup__header">
          <p class="popup__title">{{ $t('components.wallet-add.title') }}</p>
          <div class="popup__close popup-close clickable" @click="close()">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.93468 4.93468 0 11 0C17.0653 0 22 4.93468 22 11C22 17.0653 17.0653 22 11 22C4.93468 22 0 17.0653 0 11ZM13.4955 15.3104L15.3104 13.4955L12.8149 11L15.3104 8.50452L13.4955 6.68962L11 9.18511L8.5045 6.68962L6.6896 8.50452L9.18509 11L6.6896 13.4955L8.5045 15.3104L11 12.8149L13.4955 15.3104Z" fill="#297BC7"/>
            </svg>
          </div>
        </div>
        <div class="popup__content popup-currency__content">
          <ul class="currency-list">
            <li class="currency-list__item" v-for="curr in avaliableCurrencies" :key="curr">
              <a class="currency-list__link clickable" @click="createWallet(curr)">
                <span class="currency-list__link-icon">
                  <img :src="getWalletIcon(curr)">
                </span>
                <span class="currency-list__link-label"><span>{{ curr }}</span></span>
              </a>
            </li>
            <!-- <li class="currency-list__item">
              <a class="currency-list__link" href="#">
                <span class="currency-list__link-icon">
                  <img src="img/btc.svg">
                </span>
                <span class="currency-list__link-label"><span>BTC</span>  (Bitcoin)</span>
              </a>
            </li>
            <li class="currency-list__item">
              <a class="currency-list__link" href="#">
                <span class="currency-list__link-icon">
                  <img src="img/btc.svg">
                </span>
                <span class="currency-list__link-label"><span>BTC</span>  (Bitcoin)</span>
              </a>
            </li>
            <li class="currency-list__item">
              <a class="currency-list__link" href="#">
                <span class="currency-list__link-icon">
                  <img src="img/btc.svg">
                </span>
                <span class="currency-list__link-label"><span>BTC</span>  (Bitcoin)</span>
              </a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'WalletAdd',
  data() {
    return {

    };
  },
  methods: {
    createWallet(type) {
      const data = {
        name: type,
        walletType: this.$store.state.app.newWallet,
      };

      this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/${this.$store.state.app.newWallet}/${type}/account`, data)
        .then(() => {
          this.$root.snackbar.info('Wallet created!');
          this.getProfile(false);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn('Wrong token');
                this.logout();
                break;
              default:
                this.$root.snackbar.warn('Error');
            }
          }
        })
        .then(() => {
          this.close();
        });
    },
    close() {
      this.$store.dispatch('app/setNewWallet', '');
    },
  },
  computed: {
    avaliableCurrencies() {
      return this.$store.state.app.currencies.filter((curr) => {
        let type;
        if (this.$store.state.app.newWallet === 'trade') {
          type = 'wallets';
        } else if (this.$store.state.app.newWallet === 'main') {
          type = 'mainWallets';
        }
        return !this.$store.state.profile[type].some((wallet) => wallet.currency === curr);
      });
    },
  },
  mounted() {},
};
</script>
