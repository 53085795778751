import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Main/Home.vue';
import Main from '@/views/Main.vue';

import Exchange from '@/views/Main/Exchange.vue';
import BuySell from '@/views/Main/BuySell.vue';
import Options from '@/views/Main/Options.vue';
import PayOut from '@/views/Main/PayOut.vue';
import WalletHistory from '@/views/Main/WalletHistory.vue';

Vue.use(VueRouter);

// Некоторые компоненты разбиваем на чанки, дабы они подгружались по мере необходимости
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Auth/Login.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "registration" */ '@/views/Auth/Registration.vue'),
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/Auth/ForgotPassword.vue'),
  },
  {
    path: '/activate',
    name: 'Activate',
    component: () => import(/* webpackChunkName: "activate" */ '@/views/Auth/Activate.vue'),
  },
  {
    path: '',
    component: Main,
    children: [
      {
        path: '/',
        name: 'Home',
        title: 'Trident P2P marketplace, Wallet Exchange Buy Sell Crypto',
        component: Home,
      },
      {
        path: '/wallet-history/:walletType/:currency',
        name: 'WalletHistory',
        component: WalletHistory,
      },
      {
        path: '/exchange',
        name: 'Exchange',
        component: Exchange,
      },
      {
        path: '/buy-sell',
        name: 'BuySell',
        component: BuySell,
      },
      {
        path: '/payout',
        name: 'PayOut',
        component: PayOut,
      },
      {
        path: '/options',
        name: 'Options',
        component: Options,
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Main/Settings.vue'),
        children: [
          {
            path: '/settings/profile',
            name: 'Profile',
            component: () => import(/* webpackChunkName: "settings" */ '@/views/Main/Settings/Profile.vue'),
          },
          {
            path: '/settings/messages',
            name: 'Messages',
            component: () => import(/* webpackChunkName: "settings" */ '@/views/Main/Settings/Messages.vue'),
          },
          {
            path: '/settings/trade',
            name: 'Trade',
            component: () => import(/* webpackChunkName: "settings" */ '@/views/Main/Settings/Trade.vue'),
          },
          { // Редиректим на первую страницу настроек
            path: '*',
            redirect: '/settings/profile',
          },
        ],
      },
    ],
  },
  { // Редирект домой при 404
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
