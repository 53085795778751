<template>
    <div class="popup popup-notifications" style="z-index: 9999; opacity: 1; overflow: auto;">
      <div class="login-page__content">
        <div class="popup__close popup-close" style="float: right;" @click="$store.dispatch('app/toggleChangePassword')">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11C0 4.93468 4.93468 0 11 0C17.0653 0 22 4.93468 22 11C22 17.0653 17.0653 22 11 22C4.93468 22 0 17.0653 0 11ZM13.4955 15.3104L15.3104 13.4955L12.8149 11L15.3104 8.50452L13.4955 6.68962L11 9.18511L8.5045 6.68962L6.6896 8.50452L9.18509 11L6.6896 13.4955L8.5045 15.3104L11 12.8149L13.4955 15.3104Z" fill="#297BC7"/>
        </svg>
      </div>
        <div class="login-page__title yellow"><strong>CHANGE PASSWORD</strong></div>
        <form class="login-form">
          <div class="login-form__input">
            <input
              class="login-form__input-item"
              v-bind:type="password_field_type"
              placeholder="New password"
              v-model="password"
              @input="validateWithErrors"
            />
            <a class="login-form__link" href="#" @click.prevent="toggle_password_field"><img src="/img/eye.svg" width="20"></a>
          </div>
          <div class="login-form__input">
            <input
              class="login-form__input-item"
              v-model="password_confirm"
              v-bind:type="password_field_type_2"
              placeholder="Confirm password"
              @input="validateWithErrors"
            />
            <a class="login-form__link" href="#" @click.prevent="toggle_password_field_2"><img src="/img/eye.svg" width="20"></a>
            <!-- <a class="login-form__link" href="#">Forgot</a> -->
          </div>
          <p class="login-form__error">
            <span  v-for="error in curr_errors" :key="error">
              {{ error }}
            </span>
          </p>
          <div class="login-form__submit">
            <input
              class="btn btn-yellow login-form__submit-item"
              type="button"
              value="SIGN UP"
              v-bind:class="{
                'btn-disabled': !validatePassword(password_confirm) || !validateConfirmation()
                }"
              @click="signup"
            />
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ChangePassword',
  data() {
    return {
      password_confirm: '',
      password: '',
      errors: {
        password_length: 'Password must contain at least 8 characters',
        password_confirm: 'Password not confirmed',
      },
      curr_errors: [],
      password_field_type: 'password',
      password_field_type_2: 'password',
    };
  },
  methods: {
    toggle_password_field() {
      if (this.password_field_type == 'password') {
        this.password_field_type = 'text';
      } else {
        this.password_field_type = 'password';
      }
    },
    toggle_password_field_2() {
      if (this.password_field_type_2 == 'password') {
        this.password_field_type_2 = 'text';
      } else {
        this.password_field_type_2 = 'password';
      }
    },
    signup() {
      if (!this.validatePassword(this.password)) {
        this.$root.snackbar.warn('Password must be at least 8 characters');
        return false;
      }

      // delete this.$http.defaults.headers.common.Authorization;

      this.$nextTick(() => {
        this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/registration`, {
          email: this.$store.state.auth.email.toLowerCase(),
          password: this.password,
          // language: process.env.VUE_APP_DEFAULT_LOCALE.toUpperCase(),
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                this.$store.dispatch('app/toggleChangePassword');
                this.$root.snackbar.info('Success');
                this.$router.push({
                  path: '/activate',
                  params: { email: this.$store.state.auth.email.toLowerCase(), password: this.password },
                });
                // this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/logout`).then(() => {
                //   this.$store.dispatch('auth/logout');
                //   delete this.$http.defaults.headers.common.Authorization;
                // });

                break;
              case 201:
                this.$root.snackbar.info('Created new account');
                break;
              default:
                this.$root.snackbar.warn(response.data);
            }
            this.$store.dispatch('auth/setPassword', this.password);
            this.$root.snackbar.info('Code sent to your email');
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 400:
                  this.$root.snackbar.warn('Invalid email');
                  break;
                case 429:
                  this.$root.snackbar.warn(error.response.data);
                  break;
                default:
                  this.$root.snackbar.warn(error.response?.data ? error.response.data : 'Wrong email or password!');
              }
              console.log(error.response);
            } else if (error.request) {
              this.$root.snackbar.warn(error.request);
              console.log(error.request);
            } else {
              this.$root.snackbar.warn(error.message);
            }
          });
      });
      return true;
    },
    validatePassword(input) {
      if (input.length >= 8) {
        return true;
      }
      return false;
    },
    validateConfirmation() {
      if (this.password_confirm === this.password) {
        return true;
      }
      return false;
    },
    validateWithErrors: _.debounce(function () {
      this.curr_errors = [];
      if (this.password.length > 0 && this.password_confirm.length > 0) {
        Object.keys(this.errors).filter((error) => {
          if (!this.validatePassword(this.password) && error === 'password_length') {
            this.curr_errors.push(this.errors[error]);
          }
          if (!this.validateConfirmation() && error === 'password_confirm') {
            this.curr_errors.push(this.errors[error]);
          }
          return true;
        });
      }
    }, 750),
  },
  mounted() {

  },
};
</script>
