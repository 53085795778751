<template>
  <div class="offers__input">
    <slot name="prepend"></slot>
    <input
      v-bind="$attrs"
      :value="value"
      type="number"
      @input="filterValue"
      @keypress="filterInput"
      :ref="unique_name"
    >
    <slot name="append"></slot>
  </div>
</template>

<script>
import Wallet from '@/models/Wallet';

export default {
  name: 'InputCryptoValue',
  props: {
    wallet: {
      default: new Wallet({}),
      type: Wallet,
    },
    value: {
      type: [String, Number],
    },
  },
  data() {
    return {
      // eslint-disable-next-line no-underscore-dangle
      unique_name: `${this._uid}ICV`,
    };
  },
  computed: {
    // filtered_menu() {
    //   // return this.menu.filter((el) => el.enable);
    // },
  },
  methods: {
    // eslint-disable-next-line
    filterInput(event) {
      if (!this.wallet.validateInput(event)) {
        event.preventDefault();
      }
    },
    filterValue(event) {
      const stringValue = event.target.value.toString();
      let arrayValue = [];
      if (stringValue.includes('.')) {
        arrayValue = stringValue.split('.');
      }
      if (stringValue.includes(',')) {
        arrayValue = stringValue.split(',');
      }

      const value = this.wallet.filterValue(event.target.value);
      if ((arrayValue[1]?.length ?? 0) > 1) {
        if (parseFloat(event.target.value) != value) {
          // this.$refs[this.unique_name].value = value;
          this.$emit('input', value);
        } else {
          this.$emit('input', event.target.value);
        }
      } else {
        this.$emit('input', event.target.value);
      }
    },
  },
  watch: {
    // value(newValue) {
    //   console.log([parseFloat(newValue), this.wallet.filterValue(newValue)]);
    //   if (parseFloat(newValue) != this.wallet.filterValue(newValue)) {
    //     this.$refs[this.unique_name].value = this.wallet.filterValue(newValue);
    //   }
    // },
  },
  created() {
  },
  mounted() {

  },
};
</script>

<style scoped>

</style>
