<template>
  <div
    class="page-content settings-content trade-content"
    v-bind:class="{ 'full': !$store.state.app.navigation, 'not-full': $store.state.app.navigation }"
    >
    <div class="page-content__wrapper">
      <div class="page-block">
        <div class="page-block__header">
          <p class="page-block__title">
            <router-link to="/">
              <a class="back-arrow">
                <img src="/img/white-arrow.svg">
              </a>
              {{ $t('views.exchange.title') }}
            </router-link>
          </p>
        </div>
        <div class="page-block__content">
          <div class="offers-item" v-if="!loading && !this.$store.state.app.loading">
            <div class="settings-wrapper">
              <div class="settings-wrapper__item">
                <div class="settings-wrapper__content">
                  <div class="offers">
                    <div class="offers__wrapper">
                      <div class="offers__header">
                        <p class="offers__exchange">
                          1 {{ wallet_from.currency }} ≈ {{ exchange_info_curr.value }} {{ wallet_to.currency }}
                        </p>
                      </div>
                      <div class="offers__content">
                        <div class="offers__input">
                          <InputCryptoValue
                            v-model="amount_from"
                            :wallet="wallet_from"
                            @input.native="inputFrom()"
                            v-bind:class="{ 'error': amount_from > max_amount || amount_from <= 0 || minLimitError }"
                          />
                          <div class="values_group">
                          <div
                            class="max_value__input clickable value__input_desktop"
                            @click="pasteMax(max_amount)"
                          >
                            <span>
                              {{ max_amount == wallet_from.value ? 'Max' : 'Limit' }}:&nbsp;{{ toFixed(max_amount) }}
                            </span>
                          </div>
                          <div class="offers__select">
                            <!-- <select v-model="wallet_from_currency">
                              <option
                                v-for="wallet in aval_wallets_from"
                                :key="'From' + wallet.currency"
                                :value="wallet.currency"
                              >
                                {{ wallet.currency }}
                              </option>
                            </select> -->
                            <CustomSelect
                              :options="aval_wallets_from"
                              v-model="wallet_from"
                              value_key="currency"
                              value_label="currency"
                              class="select"
                            />
                          </div>
                          </div>
                        </div>
                        <div
                          class="max_value__input clickable value__input_mobile"
                          @click="pasteMax(max_amount)"
                        >
                            <span>
                              {{ max_amount == wallet_from.value ? 'Max' : 'Limit' }}:&nbsp;{{ toFixed(max_amount) }}
                            </span>
                        </div>
                        <div class="offers__arrow">
                          <img
                            class="clickable arrow_desktop"
                            src="img/offers-arrow.svg"
                            @click="swap()"
                          >
                          <img
                            class="clickable arrow_mobile"
                            src="img/offers-arrow_blue.svg"
                            @click="swap()"
                          >
                        </div>
                        <div
                          class="max_value__input clickable value__input_mobile"
                          v-bind:class="{ 'error': exchange_info_curr.limitSell == 0 }"
                          style="width: auto;"
                          @click="pasteLimit(exchange_info_curr.limitSell)"
                        >
                            <span>
                              Limit:&nbsp;{{ exchange_info_curr.limitSell }}
                            </span>
                        </div>
                        <div class="offers__input">
                          <InputCryptoValue
                            v-model="amount_to"
                            :wallet="wallet_to"
                            @input.native="inputTo()"
                            v-bind:class="{ 'error': amount_to > exchange_info_curr.limitSell }"
                          />
                          <!--
                            @keypress="inputFixed"
                            @input="amount_to = $event.target.value"
                          -->
                          <div class="values_group">
                          <div
                            class="max_value__input clickable value__input_desktop"
                            v-bind:class="{ 'error': exchange_info_curr.limitSell == 0 }"
                            style="width: auto;"
                            @click="pasteLimit(exchange_info_curr.limitSell)"
                          >
                            <span>
                              Limit:&nbsp;{{ exchange_info_curr.limitSell }}
                            </span>
                          </div>
                          <div class="offers__select">
                            <!-- <select v-model="wallet_to_currency">
                              <option
                                v-for="wallet in aval_wallets_to"
                                :key="'To' + wallet.currency"
                                :value="wallet.currency"
                              >
                                {{ wallet.currency }}
                              </option>
                            </select> -->
                            <CustomSelect
                              :options="aval_wallets_to"
                              v-model="wallet_to"
                              value_key="currency"
                              value_label="currency"
                              class="select"
                            />
                          </div>
                          </div>
                        </div>
                      </div>
                      <div class="offers__desc">
                        <div class="offers__desc-item">
                          <p class="offers__desc-title">{{ $t('views.exchange.commission') }}</p>
                          <p class="offers__desc-text">
                            <span v-if="finalCommission != exchange_info_curr.limitCommission">
                              {{ exchange_info_curr.commission }}% ≈
                            </span>
                            {{ toFixed(finalCommission, Math.log10(wallet_from.blockchainSettings.unit)) }}
                            {{ wallet_from.currency }}
                          </p>
                        </div>
                        <div class="offers__desc-item">
                          <p class="offers__desc-title">{{ $t('views.exchange.amount') }}</p>
                          <p class="offers__desc-text">
                            {{ toFixed(wallet_from.value, Math.log10(wallet_from.blockchainSettings.unit)) }}
                            {{ wallet_from.currency }}
                          </p>
                        </div>
                        <div class="offers__desc-item">
                          <p class="offers__desc-text">
                            {{ toFixed(wallet_to.value, Math.log10(wallet_to.blockchainSettings.unit)) }}
                            {{ wallet_to.currency }}
                          </p>
                        </div>
                        <div class="offers__desc-item">
                          <p class="offers__desc-title">{{ $t('views.exchange.final-amount') }}</p>
                          <p class="offers__desc-text">
                            {{ toFixed(total_amount_from, Math.log10(wallet_from.blockchainSettings.unit)) }}
                            {{ wallet_from.currency }}
                          </p>
                        </div>
                        <div class="offers__desc-item">
                          <p class="offers__desc-text">
                            {{ toFixed(total_amount_to, Math.log10(wallet_to.blockchainSettings.unit)) }}
                            {{ wallet_to.currency }}
                          </p>
                        </div>
                      </div>
                      <div class="offers__footer">
                        <a
                          class="btn btn-yellow offers__btn popup-trigger"
                          v-bind:class="{
                            'btn-disabled':!canExchange,
                            'clickable':canExchange
                          }"
                          @click="exchange()"
                          data-popup=".popup-exchange"
                        >
                          {{ $t('views.exchange.exchange-btn') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LoadingSpinner v-if="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import customSelect from 'custom-select';
import CustomSelect from '@/components/ui/Select.vue';
import InputCryptoValue from '@/components/ui/InputCryptoValue.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'Exchange',
  data() {
    return {
      prefer_wallet: {},
      amount_from: 0,
      amount_to: 0,
      wallet_to_currency: '',
      wallet_from_currency: '',
      exchange_info: [],
      loading: true,
    };
  },
  methods: {
    setWallet(event, tofrom) {
      if (tofrom == 'to') {
        this.wallet_to = event;
      } else {
        this.wallet_from = event;
      }
    },
    inputFixed(event, unit = 100000000) {
      // console.log(Math.log10(unit));
      const txt = String.fromCharCode(event.which);
      if (!txt.match(/[0-9]|.|,/)) {
        event.preventDefault();
      }
      if (event.target.value.includes('.')) {
        if (event.target.value.split('.')[1].length > (Math.log10(unit) - 1) || event.target.value.split('.')[1].length > 7) {
          event.preventDefault();
        }
      }
      if (event.target.value.includes(',')) {
        if (event.target.value.split(',')[1].length > (Math.log10(unit) - 1) || event.target.value.split('.')[1].length > 7) {
          event.preventDefault();
        }
      }
    },
    async getCurrencyInfo(load = true, stopLoad = true) {
      this.loading = load;
      await this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/exchange`)
        .then((response) => {
          this.exchange_info = response.data;
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
          }
        }).then(() => {
          setTimeout(() => this.getCurrencyInfo(false), 1000 * 60);
          this.$nextTick(() => {
            if (load && stopLoad) {
              this.loading = false;
            }
          });
        });
    },
    exchange() {
      const popupData = { show: true };
      popupData.title = 'Confirm exchange';
      popupData.text = `
        ${this.toFixed(this.amount_from, Math.log10(this.wallet_from.blockchainSettings.unit))}
        ${this.wallet_from_currency}
        to
        ${this.toFixed(this.amount_to, Math.log10(this.wallet_to.blockchainSettings.unit))}
        ${this.wallet_to_currency}
      `;
      popupData.buttons = [{
        text: 'Confirm',
        class: 'btn-yellow',
        action: () => {
          this.$store.dispatch('app/toggleUniPopup');
          this.exchangeDo();
        },
      }];
      this.$store.dispatch('app/setUniPopup', popupData);
    },
    async exchangeDo() {
      if (Number(this.amount_from) > this.exchange_info_curr.limitBuy) {
        const popupData = { show: true };
        popupData.title = 'Limit error';
        popupData.text = `
          We can't buy ${this.toFixed(this.amount_from, Math.log10(this.wallet_from.blockchainSettings.unit))}
          ${this.wallet_from_currency}
        `;
        this.$store.dispatch('app/setUniPopup', popupData);
        return;
      }

      if (Number(this.amount_to) > this.exchange_info_curr.limitSell) {
        const popupData = { show: true };
        popupData.title = 'Limit error';
        popupData.text = `
          We can't sell ${this.toFixed(this.amount_to, Math.log10(this.wallet_to.blockchainSettings.unit))}
          ${this.wallet_to_currency}
        `;
        this.$store.dispatch('app/setUniPopup', popupData);
        return;
      }
      this.loading = true;
      const data = {
        symbol1: this.wallet_from.currency,
        symbol2: this.wallet_to.currency,
        volume: Number(this.amount_from),
        value: Number(this.exchange_info_curr.value),
        commission: Number(this.exchange_info_curr.commission),
      };

      await this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/exchange`, data)
        .then(() => {
          this.$root.snackbar.info(this.$i18n.t('response-messages.success.exchange'));
          this.getProfile(true);
          this.$router.push({ path: '/' });
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              case 403:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.exchange-expired'));
                this.getCurrencyInfo(false);
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        }).then(() => {
          this.loading = false;
        });
    },
    pasteMax(value) {
      this.amount_from = value;
      this.inputFrom();
    },
    swap() {
      const buffer = this.wallet_from;
      this.wallet_from = this.wallet_to;
      this.$nextTick(() => { this.wallet_to = buffer; });
      this.amount_from = 0;
      this.amount_to = 0;
    },
    pasteLimit(value) {
      this.amount_to = value;
      this.inputTo();
    },
    correctCommision(value, wallet) {
      if (value < this.math.pow(0.1, Math.log10(wallet.blockchainSettings.unit))) {
        if (value < this.math.pow(0.1, 8)) {
          return this.toFixed(this.math.pow(0.1, 8));
        }
        return this.math.pow(0.1, Math.log10(wallet.blockchainSettings.unit));
      }
      return this.toFixed(value, Math.log10(wallet.blockchainSettings.unit));
    },
    inputFrom() {
      this.amount_to = this.toFixed(
        this.math.multiply(this.amount_from - this.finalCommission, this.exchange_info_curr.value),
        Math.log10(this.wallet_to.blockchainSettings.unit),
      );
      return this.amount_from;
    },
    inputTo() {
      this.amount_from = this.toFixed(this.amount_to
        / (this.exchange_info_curr.value - (this.math.multiply(this.exchange_info_curr.commission, this.exchange_info_curr.value) / 100)),
      Math.log10(this.wallet_from.blockchainSettings.unit));
    },
  },
  // beforeRouteEnter(to, from, next) {
  // },
  computed: {
    wallet_from: {
      get() {
        // console.log(this.exchange_info);
        return this.$store.state.profile.wallets.filter((wallet) => this.wallet_from_currency === wallet.currency)[0];
      },
      set(value) {
        this.wallet_from_currency = value.currency;
      },
    },
    wallet_to: {
      get() {
        return this.$store.state.profile.wallets.filter((wallet) => this.wallet_to_currency === wallet.currency
        && this.exchange_info.some((val) => val.symbol1 === wallet.currency))[0];
      },
      set(value) {
        this.wallet_to_currency = value.currency;
      },
    },
    finalCommission() {
      const commission = this.math.multiply(this.amount_from, this.exchange_info_curr.commission) / 100;
      if (commission <= this.exchange_info_curr.limitCommission) {
        return this.exchange_info_curr.limitCommission;
      }
      return commission;
    },
    // amount_to: {
    //   get() {
    //     return this.toFixed(
    //       this.math.multiply(this.amount_from, this.exchange_info_curr.value),
    //       Math.log10(this.wallet_to.blockchainSettings.unit),
    //     );
    //   },
    //   set(value) {
    //     // if (value === '') { this.amount_from = 0; return; }
    //     this.amount_from = Number(
    //       this.math.format(
    //         this.math.divide(
    //           value == false ? 0 : value,
    //           this.exchange_info_curr.value,
    //         ),
    //         { notation: 'fixed', precision: 8 },
    //       ),
    //     );
    //   },
    // },
    aval_wallets_from() {
      return this.$store.state.profile.wallets.filter(
        (wallet) => this.exchange_info.some((val) => val.symbol1 === wallet.currency),
      );
    },
    aval_wallets_to() {
      return this.$store.state.profile.wallets.filter((wallet) => this.wallet_from_currency !== wallet.currency
      && this.exchange_info.some((val) => val.symbol2 === wallet.currency));
    },
    total_amount_from() {
      // return 1;
      return this.math.subtract(
        this.wallet_from.value,
        this.amount_from,
      );
    },
    total_amount_to() {
      // return 1;
      return this.math.add(this.amount_to, this.wallet_to.value);
    },
    exchange_info_curr() {
      let value = this.exchange_info.filter((pair) => pair.symbol1 === this.wallet_from_currency
        && pair.symbol2 === this.wallet_to_currency)[0];

      if (!value) {
        /* eslint-disable-next-line */
        this.wallet_from_currency = this.exchange_info[0].symbol1;
        /* eslint-disable-next-line */
        value = this.exchange_info.filter((pair) => pair.symbol1 === this.wallet_from_currency
          && pair.symbol2 === this.wallet_to_currency)[0];
      }
      return value;
    },
    max_amount() {
      return this.wallet_from.value <= this.exchange_info_curr.limitBuy ? this.wallet_from.value : this.exchange_info_curr.limitBuy;
      // return this.math.divide(
      //   this.wallet_from.value,
      //   this.math.add(1, this.math.divide(this.exchange_info_curr.commission, 100)),
      // );
    },
    canExchange() {
      return this.amount_from <= this.max_amount
        && this.amount_to <= this.exchange_info_curr.limitSell
        && this.amount_from > 0
        && !this.minLimitError;
    },
    minLimitError() {
      return this.exchange_info_curr?.limitCommission >= this.amount_from;
    },
  },
  watch: {
    wallet_from: {
      handler() {
        this.$set(this, 'wallet_to', this.aval_wallets_to[0]);
        this.amount_from = 0;
        this.amount_to = 0;
      },
      // immediate: true, // Расчитать при создании
    },
    wallet_to: {
      handler() {
        this.amount_from = 0;
        this.amount_to = 0;
      },
    },
  },
  created() {
    const walletsWithValue = this.$store.state.profile.wallets.filter((wallet) => wallet.value > 0).length;
    // const walletsWithValue = this.$store.state.profile.wallets.filter((wallet) => wallet.value > 0).length;
    if (!walletsWithValue/*  && false */) {
      this.$router.push({
        path: '/',
      });
      const popupData = {
        show: true,
        template: 'recieve_noaddress',
        text: 'You didnt have any value on your wallets. Top up your wallet balance.',
      };
      popupData.buttons = [{
        text: 'OK',
        class: 'btn-yellow',
        action: () => {
          this.$store.dispatch('app/toggleUniPopup');
        },
      }];
      this.$store.dispatch('app/setUniPopup', popupData);
    }
    if (this.$store.state.profile.wallets.length < 2/*  && false */) {
      this.$router.push({
        path: '/',
      });
      const popupData = {
        show: true,
        template: 'recieve_noaddress',
        text: 'For exchange you need to have two wallets created',
      };
      popupData.buttons = [{
        text: 'OK',
        class: 'btn-yellow',
        action: () => {
          this.$store.dispatch('app/toggleUniPopup');
        },
      }];
      this.$store.dispatch('app/setUniPopup', popupData);
    }
    // FIXME: errors in console

    this.getCurrencyInfo(true, false).then(() => {
      let firstWallet;
      /* eslint-disable-next-line */
      if (this.$route.params?.wallet_from?.currency && this.exchange_info.some((val) => val.symbol1 === this.$route.params.wallet_from.currency)) {
        firstWallet = this.$route.params.wallet_from;
      } else {
        /* eslint-disable-next-line */
        firstWallet = this.$store.state.profile.wallets.filter((wallet) => this.exchange_info.some((val) => val.symbol1 === wallet.currency))[0];
      }
      // this.$set(this, 'wallet_from', firstWallet);

      this.loading = false;
      [this.wallet_from] = this.aval_wallets_from.filter((wal) => wal.currency == firstWallet.currency);
      // this.$nextTick(() => {
      //   customSelect('.offers__select kek');
      // });
    });
  },
  components: {
    LoadingSpinner,
    CustomSelect,
    InputCryptoValue,
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.offers__exchange {
  margin-bottom: 10px;
}

.offers__content > .offers__input {
  margin-top: 0;
}

.offers__arrow img {
  transition: transform 0.33s;
}
.offers__arrow img:hover {
  transform: rotate(180deg);
}
.offers__input {
  align-items: center;
}

.values_group {
  display: flex;
  align-items: center;
  padding: 0;
}

.max_value__input.value__input_desktop {
  border-right: 2px solid #14386E;
  height: 50%;
}

.max_value__input.value__input_desktop {
  line-height: 30px;
  padding-right: 15px;
}

.max_value__input.value__input_mobile {
  text-align: center;
  margin-right: 0;
  padding: 15px 0;
  border-right: none;
}

.error {
  color: red;
}

@media screen and (max-width: 991px) {
  .offers__arrow {
    margin: 0;
  }
}
</style>
