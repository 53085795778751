<template>
  <div>
    <Header @getProfile="getProfile"/>
    <Notifications
      v-if="$store.state.app.notifications_show"
    />
    <div class="page-wrapper">
      <div class="page-wrapper__content">
        <Navigation/>
          <transition
            name="fade"
            mode="out-in"
            appear
          >
            <router-view
              v-if="!$store.state.app.loading"
            />
            <div
              class="page-content"
              v-if="$store.state.app.loading"
              v-bind:class="{ 'full': !$store.state.app.navigation, 'not-full': $store.state.app.navigation }"
            >
              <div class="page-content__wrapper">
                <div class="page-block">
                  <LoadingSpinner/>
                </div>
              </div>
            </div>
          </transition>
      </div>
    </div>
    <transition name="fade">
      <RecieveQR
        v-if="Object.keys($store.state.app.recieveWallet).length"
      />
      <WalletAdd
        v-if="$store.state.app.newWallet"
      />
      <ChangePassword
        v-if="$store.state.app.popupChangePassword"
      />
      <UniPopup
        v-if="$store.state.app.uniPopup.show"
      />
    </transition>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navigation from '@/components/Navigation.vue';
import RecieveQR from '@/components/popups/RecieveQR.vue';
import WalletAdd from '@/components/popups/WalletAdd.vue';
import Notifications from '@/components/popups/Notifications.vue';
import ChangePassword from '@/components/popups/ChangePassword.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import UniPopup from '@/components/popups/UniPopup.vue';

export default {
  name: 'Main',
  components: {
    Header,
    Navigation,
    RecieveQR,
    WalletAdd,
    Notifications,
    ChangePassword,
    LoadingSpinner,
    UniPopup,
  },
  methods: {
    initSocket(cooldown) {
      const socket = new WebSocket(
        `wss://${process.env.VUE_APP_ROOT_URL.split('//')[1]}/message`,
      );
      this.$store.dispatch('app/setSocket', socket);

      socket.onopen = () => {
        console.log('[ws] started');
        socket.send(JSON.stringify({ type: 'auth', token: this.$store.state.auth.token }));
      };

      socket.onmessage = (message) => {
        const data = JSON.parse(message.data);
        console.log('[ws] got message');
        if (
          Object.prototype.hasOwnProperty.call(data, 'type')
          && Object.prototype.hasOwnProperty.call(data, 'value')
          && data.type === 'wallet'
          && !this.$store.state.app.notifications_show
        ) {
          this.getNotifications(false);
          this.getProfile(false);
        }
      };

      socket.onclose = () => {
        if (this.$store.state.auth?.token?.length) {
          console.log(`[ws] closed. Restarting after ${cooldown / 1000} seconds...`);
          // Если происходит рекурсия, переподключаем в геометрической прогресии
          setTimeout(() => { this.initSocket(cooldown * 2); }, cooldown);
        } else {
          console.log('[ws] You logged out. Websocket destroyed');
        }
      };

      socket.onerror = (error) => {
        console.log(`[ws] ${error.message}`);
      };
    },
  },
  mounted() {
    // iphone width calc
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.getProfile(false).then(
      (response) => {
        this.$i18n.locale = response.data?.language ? response.data.language.toLowerCase() : 'en';
        this.$store.dispatch('app/setCurrencies', response.data.availableCurrencies);
        this.initSocket(1000);
        this.getPaymentData();
        this.getNotifications(false);
      },
    );
  },
};
</script>

<style>

</style>
