<template>
  <div class="popup popup-qr" style="z-index: 9999; opacity: 1; overflow: auto;">
    <div class="popup__wrapper">
      <div class="popup__header">
        <p class="popup__title"><span>{{ $t('components.popups.recieve-qr.title', [currentWallet.currency]) }}</span></p>
        <div
          class="popup__close popup-close"
          @click="$store.dispatch('app/setWalletForRecieve', {})"
        >
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 11C0 4.93468 4.93468 0 11 0C17.0653 0 22 4.93468 22 11C22 17.0653 17.0653 22 11 22C4.93468 22 0 17.0653 0 11ZM13.4955 15.3104L15.3104 13.4955L12.8149 11L15.3104 8.50452L13.4955 6.68962L11 9.18511L8.5045 6.68962L6.6896 8.50452L9.18509 11L6.6896 13.4955L8.5045 15.3104L11 12.8149L13.4955 15.3104Z"
              fill="#297BC7"
            />
          </svg>
        </div>
      </div>
      <div class="popup__content">
        <div class="popup-qr">
          <div class="popup-qr__wrapper">
            <p class="popup-qr__title">{{ $t('components.popups.recieve-qr.qr-title', [currentWallet.currency]) }}</p>
            <div class="popup-qr__img">
              <!-- <img src="img/qr.png" /> -->
              <qr-code :text="currentAddress"></qr-code>
            </div>
          </div>
          <div class="popup-qr__footer">
            <div class="popup-qr__address">
              <p class="popup-qr__address-title">
                {{ $t('components.popups.recieve-qr.address-title', [currentWallet.currency]) }}
              </p>
              <div class="wallet-card">
                <div class="wallet-card__address popup-qr__wallet-address">
                <a
                  class="wallet-card__address-select clickable"
                  @click="toClipboard(currentWallet.addresses[0])"
                >
                  {{ currentWallet.addresses[0] }}
                </a>
                  <!-- <select
                    class="wallet-card__address-select popup__address-select"
                    v-model="currentAddress"
                  >
                    <option
                      v-for="address in currentWallet.addresses"
                      :key="address"
                      :value="address"
                    >
                      {{ address }}
                    </option>
                  </select>
                  <div class="wallet-card__address-control">
                    <a class="wallet-card__address-link clickable" @click="toClipboard(currentAddress)">
                      <svg class="icon icon-copy">
                        <use xlink:href="/img/svg/sprite.svg#copy"></use>
                      </svg>
                    </a>
                    <a
                      class="wallet-card__address-link wallet-card__address-link_descktop"
                      v-if="
                        currentWallet.blockchainSettings.blockchainNetwork !== 'ETH'
                        && !currentWallet.blockchainSettings.blockchainNetwork.includes('TOKEN')
                      "
                      @click="newAddress()"
                    >
                      <svg class="icon icon-add">
                        <use xlink:href="/img/svg/sprite.svg#add"></use>
                      </svg>
                    </a>
                  </div> -->

                  <!-- <div class="wallet-card__address-control wallet-card__address-control_second">
                    <a class="wallet-card__address-link" href="#">
                      <span class="wallet-card__address-link-label">
                        {{ $t('components.popups.recieve-qr.address-change') }}
                      </span>
                      <svg class="icon icon-add">
                        <use xlink:href="/img/svg/sprite.svg#add"></use></svg></a
                    ><a class="wallet-card__address-link" href="#">
                      <span class="wallet-card__address-link-label">
                        {{ $t('components.popups.recieve-qr.address-change') }}
                      </span>
                      <svg class="icon icon-arrow-select">
                        <use xlink:href="/img/svg/sprite.svg#arrow-select"></use></svg
                    ></a>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="popup-paid__footer" style="display: flex; justify-content: center;">
              <a class="btn-blue btn" style="width: 75%;" :href="toMail()">
                Share
                <img src="/img/svg/share.svg" style="height: 29px; margin-left: 15px"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wallet from '@/models/Wallet';

export default {
  name: 'RecieveQR',
  data() {
    return {
      currentWallet: new Wallet(this.$store.state.app.recieveWallet),
      currentAddress: '',
    };
  },
  methods: {
    toClipboard(data) {
      navigator.clipboard.writeText(data).then(() => {
        this.$root.snackbar.info(this.$i18n.t('components.popups.recieve-qr.clipboard-saved'));
      }, () => {
        this.$root.snackbar.warn(this.$i18n.t('components.popups.recieve-qr.clipboard-problem'));
      });
    },
    toMail() {
      const url = `mailto:?subject=Pay me&body=My Public Address to Recieve ${this.currentWallet.currency}: ${this.currentAddress}
      %0APay me via Trident Wallet: ${window.location.origin}/${this.currentAddress}`;
      return url;
    },
    newAddress() {
      const data = {
        name: this.currentWallet.currency,
        walletType: this.currentWallet.walletType,
      };

      this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile/wallet/address`, data)
        .then(() => {
          this.$root.snackbar.info('Address created!');
          this.getProfile(false);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              case 403:
                this.$root.snackbar.warn(error.response.data);
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            // console.log(error);
          }
        });
    },
  },
  computed: {
    showAddress() {
      return (
        !this.currentWallet.blockchainSettings.blockchainNetwork.includes('TOKEN') || this.currentWallet.walletType == 'main'
      ) && this.currentWallet.accounts.length;
    },
    canRecieve() {
      return this.showAddress || this.currentWallet.walletType == 'main';
      /* this.$store.state.profile.mainWallets.filter((wallet) => wallet.currency === this.wallet.currency)?.length */
    },
  },
  // watch: {
  //   currentWallet:
  // },
  mounted() {
    this.$store.dispatch('app/setBodyScroll', false);

    /* eslint-disable-next-line */
    this.currentAddress = this.currentWallet.accounts[0].address;
  },
  destroyed() {
    this.$store.dispatch('app/setBodyScroll', true);
  },
};
</script>
